<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="viewSelectedTradeModal"
      :title="$t('transfers.titles.view_selected_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('transfers.modals.view_trade_modal_ok')"
      :cancel-title="$t('transfers.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
    >

    <template #modal-header="{ close }">
        <h5 style="color: white;">{{ $t('transfers.titles.view_selected_trade') }}</h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
              <b style="color: white;"> {{$t('transfers.modals.trade_not_verified')}} </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
              <b style="color: white;"> {{$t('transfers.modals.verified_trade')}} </b>
        </p>

      </template>
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Transaction Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card
                  :title="$t('transfers.titles.transactions_details')"
                  style="width: 100% !important;"
                >
                  <hr />
                  <b-row>
                    <div class="d-flex justify-content-end mb-1" style="width: 100%;">
                      <b-col cols="12" :md="tradeForm.tradeRef > 999 ? '3' : '2'" sm="6" >
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.trade_ref')"
                          >
                          <b-input-group
                              :prepend="$t('transfers.trade_form.trade_ref')"
                            >
                            <b-form-input
                              style="background: aliceblue !important;"
                              id="tradeRef"
                              v-model="tradeForm.tradeRef"
                              name="tradeRef"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.trade_ref')
                              "
                              autofocus
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <div class="d-flex" style="width: 100%;">
                      <!-- Transaction type -->
                      <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.transaction_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.transaction_type')"
                            rules="required"
                          >
                            <v-select
                              ref="transactionType"
                              v-model="tradeForm.transactionType"
                              :options="optTransactionTypes"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.transaction_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>


                        
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >

                    <v-select
                      :disabled="true"
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'
                      ref="costCenter"

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>
                    </b-col>



                      <!-- Entry date-->
                      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.entry_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.entry_date')"
                            rules="required"
                          >
                            <b-input-group
                              :class="
                                tradeForm.entryDateName == 'Sunday' ||
                                tradeForm.entryDateName == 'Saturday'
                                  ? 'entryDateName'
                                  : ''
                              "
                              :append="tradeForm.entryDateName"
                            >
                              <flat-pickr
                                ref="entryDate"
                                :placeholder="
                                  $t('transfers.trade_form.entry_date')
                                "
                                v-model="tradeForm.entryDate"
                                onClose="testClose"
                                class="form-control"
                                :config="entryDateConfig"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                              />
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Entry time-->
                      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.entry_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.entry_time')"
                            rules="required"
                          >
                            <cleave
                              ref="entryTime"
                              id="entryTime"
                              v-model="tradeForm.entryTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('transfers.trade_form.entry_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Value date-->
                      <b-col cols="12" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.value_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.value_date')"
                            rules="required"
                          >
                          <b-input-group
                              :class="
                                tradeForm.valueDateName == 'Sunday' ||
                                tradeForm.valueDateName == 'Saturday'
                                  ? 'valueDateName'
                                  : ''
                              "
                              :append="tradeForm.valueDateName"
                            >
                            <flat-pickr
                              ref="valueDate"
                              :placeholder="
                                $t('transfers.trade_form.value_date')
                              "
                              v-model="tradeForm.valueDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                          </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- Trade/Ops User -->
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('transfers.trade_form.trade_ops_user')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.trade_ops_user')"
                          rules="required"
                        >
                          <v-select
                            ref="tcUser"
                            v-model="tradeForm.tcuser"
                            :options="optTcusers"
                            :clearable="false"
                            :placeholder="
                              $t('transfers.trade_form.select_trade_ops_user')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- UTI -->
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('transfers.trade_form.uti')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.uti')"
                        >
                          <b-form-input
                            ref="tcUti"
                            id="tcuti"
                            v-model="tradeForm.tcUti"
                            name="tcuti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('transfers.trade_form.uti')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- ULI Unique link id -->
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('transfers.trade_form.unique_link_id')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.unique_link_id')"
                        >
                          <b-form-input
                            ref="uniqueLinkId"
                            id="unique_link_id"
                            v-model="tradeForm.uniqueLinkID"
                            name="unique_link_id"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.unique_link_id')
                            "
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Related Trade UTI -->
                    <b-col cols="12" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('transfers.trade_form.related_trade_uti')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('transfers.trade_form.related_trade_uti')"
                        >
                          <b-form-input
                            ref="relatedTradeUti"
                            id="related_trade_uti"
                            v-model="tradeForm.relatedTradeUti"
                            name="related_trade_uti"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('transfers.trade_form.related_trade_uti')
                            "
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>

              <b-row>
                <!-- /******************************* Details of Account 1 ***************************************************************** */ -->
                <b-col cols="12" sm="12" md="6" lg="6" xl="6" class="pl-0">
                  <b-card
                    :title="$t('transfers.titles.details_account_1')"
                    style="width: 100% !important;"
                  >
                    <hr />

                    <div class="d-flex" style="width: 100%;">
                      <!-- account name1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_name')"
                            rules="required"
                          >
                            <v-select
                              ref="accountName1"
                              v-model="tradeForm.accountName1"
                              :options="optAccountNames1"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_name')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingAccountName1"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account 1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account')"
                          >
                            <b-form-input
                              ref="accountNumber1"
                              id="accountNumber1"
                              v-model="tradeForm.accountNumber1"
                              name="accountNumber1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('transfers.trade_form.account')"
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!-- account code1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_code')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_code')"
                            rules="required"
                          >
                            <v-select
                              ref="accountCode1"
                              v-model="tradeForm.accountCode1"
                              :options="optAccountCodes1"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_code')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- account type1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_type')"
                            rules="required"
                          >
                            <b-form-input
                              ref="accountType1"
                              id="accountType1"
                              v-model="tradeForm.accountType1"
                              name="accountType1"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.account_type')
                              "
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!--  currency 1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group :label="ccyDescription">
                          <validation-provider
                            #default="{ errors }"
                            :name="ccyDescription"
                            rules="required"
                          >
                            <v-select
                              ref="ccy1"
                              v-model="tradeForm.ccy1"
                              :options="optCurrencies1"
                              :clearable="false"
                              :placeholder="ccyDescription"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- amount 1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.amount')"
                            rules="required"
                          >
                            <cleave
                              ref="amount1"
                              id="amount1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.amount')"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!-- cost 1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group :label="$t('transfers.trade_form.cost')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.cost')"
                          >
                            <cleave
                              ref="cost1"
                              id="cost1"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cost1"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.cost')"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!--  description 1 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.description')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.description')"
                            rules="required"
                          >
                            <v-select
                              ref="description1"
                              v-model="tradeForm.description1"
                              :options="optDescriptions1"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_desc')
                              "
                              :reduce="(val) => val.description"
                              label="description"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div
                      class="d-flex justify-content-center"
                      style="width: 100%;"
                    >
                      <!-- note 1 -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group :label="$t('transfers.trade_form.note')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="note1"
                              id="textarea-no-resize"
                              :placeholder="$t('transfers.trade_form.note')"
                              rows="2"
                              no-resize
                              v-model="tradeForm.note1"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-card>
                </b-col>
                <!-- /******************************* Details of Account 2 ***************************************************************** */ -->

                <b-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="animate__animated animate__fadeIn"
                  v-if="tradeForm.transactionType == 'Internal Transfer'"
                >
                  <b-card
                    :title="$t('transfers.titles.details_account_2')"
                    style="width: 100% !important;"
                  >
                    <hr />

                    <div class="d-flex" style="width: 100%;">
                      <!-- account name 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_name')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_name')"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              ref="accountName2"
                              v-model="tradeForm.accountName2"
                              :options="optAccountNames2"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_name')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingAccountName2"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- account 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account')"
                          >
                            <b-form-input
                              ref="accountNumber2"
                              id="accountNumber2"
                              v-model="tradeForm.accountNumber2"
                              name="accountNumber2"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('transfers.trade_form.account')"
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!-- account code 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_code')"
                          :rules="
                            tradeForm.transactionType == 'Internal Transfer'
                              ? 'required'
                              : ''
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_code')"
                            rules="required"
                          >
                            <v-select
                              ref="accountCode2"
                              v-model="tradeForm.accountCode2"
                              :options="optAccountCodes2"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_account_code')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- account type 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.account_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.account_type')"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <b-form-input
                              ref="accountType2"
                              id="accountType2"
                              v-model="tradeForm.accountType2"
                              name="accountType2"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('transfers.trade_form.account_type')
                              "
                              autofocus
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!--  currency 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group :label="ccyDescription">
                          <validation-provider
                            #default="{ errors }"
                            :name="ccyDescription"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              ref="ccy2"
                              v-model="tradeForm.ccy2"
                              :options="optCurrencies2"
                              :clearable="false"
                              :placeholder="ccyDescription"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- amount 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.amount')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.amount')"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              ref="amount2"
                              id="amount2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.amount2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.amount')"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div class="d-flex" style="width: 100%;">
                      <!-- cost 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group :label="$t('transfers.trade_form.cost')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.cost')"
                          >
                            <cleave
                              ref="cost2"
                              id="cost2"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.cost2"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('transfers.trade_form.cost')"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!--  description 2 -->
                      <b-col cols="12" xl="6" lg="6" md="6" sm="12">
                        <b-form-group
                          :label="$t('transfers.trade_form.description')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.description')"
                            :rules="
                              tradeForm.transactionType == 'Internal Transfer'
                                ? 'required'
                                : ''
                            "
                          >
                            <v-select
                              ref="description2"
                              v-model="tradeForm.description2"
                              :options="optDescriptions2"
                              :clearable="false"
                              :placeholder="
                                $t('transfers.trade_form.select_desc')
                              "
                              :reduce="(val) => val.description"
                              label="description"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <div
                      class="d-flex justify-content-center"
                      style="width: 100%;"
                    >
                      <!-- note 2 -->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group :label="$t('transfers.trade_form.note')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('transfers.trade_form.note')"
                          >
                            <b-form-textarea
                              ref="note2"
                              id="textarea-no-resize"
                              :placeholder="$t('transfers.trade_form.note')"
                              rows="2"
                              no-resize
                              v-model="tradeForm.note2"
                              :disabled="true"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <!-- <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /> searching please wait...
            </p>
          </div>
        </template> -->
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            id="tradeAction-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="float-left mb-0"
            :disabled="actionsData == null"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              style="margin-right: 0.2rem;"
            />

            <span class="text-nowrap">{{ $t('transfers.buttons.trade_actions') }}</span>
          </b-button>
            <b-popover
              target="tradeAction-button"
              placement="top"
              triggers="click"
              id="actions-popover"
            >
              <template #title>
                {{ $t('transfers.titles.trade_actions') }}
              </template>
              <b-table-simple hover small caption-top responsive bordered>
                <b-tbody>
                  <b-tr v-for="(action, index) in actionsData" :key="index">
                  <td style="width: 20%;">
                    {{ action.type }}
                  </td>
                  <td style="width: 20%;">
                    {{ action.user }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.date }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.reason }}
                  </td>
                </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-popover>
            
            <p class="d-inline ml-1" v-if="changedInputs.length > 0">
            <b-icon
              class="ml-1"
              title=null
              icon="record-fill"
              style="color:orange!important"
              data-toggle="tooltip"
              data-placement="top"
              font-scale="0.5"
            ></b-icon
            ><b style="margin-left:0.3rem;color:#6e6b7b;font-size: 0.7rem;">{{
              $t('transfers.modals.inputs_written_red_inputs')
            }}</b>
          </p>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="tradeOkOperation"
          >
            {{ $t('transfers.modals.view_trade_modal_ok') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="md"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
            {{$t('transfers.modals.modal_close')}}
          </b-button>
        </div>
      </template>
      {{/****END*** view for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import transfersStoreModule from "./transfersStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
      const TRANSFERS_APP_STORE_MODULE_NAME = "transfers";
      // Register module
      if (!store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
        store.registerModule(
          TRANSFERS_APP_STORE_MODULE_NAME,
          transfersStoreModule
        );
  
      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
          store.unregisterModule(TRANSFERS_APP_STORE_MODULE_NAME);
      });
    },

  mounted() {},

  data() {
    return {
      viewSelectedTradeModal: false,
      checkVerify: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingAccountName1: false,
      loadingAccountName2: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optTransactionTypes: [
        "Transfer In",
        "Transfer Out",
        "Internal Transfer",
        "Fee",
        "Coupon Payment",
        "Dividend Payment",
        "Overnight Interest",
        "Realised PnL",
      ],
      optTcusers: [],
      optAccountNames1: [],
      searchedAccountNames1: [],
      searchedAccountNames2: [],
      optAccountCodes1: [],
      optCurrencies1: [],
      optDescriptions1: [],
      optAccountNames2: [],
      optAccountCodes2: [],
      optCurrencies2: [],
      optDescriptions2: [],
      ccy1Locked: false,
      ccy2Locked: false,
      cost1Enabled: false,
      cost2Enabled: false,
      oldCcy1: null,
      ccyDescription: "Currency*",
      changedInputs: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      actionsData: [],
      tradeForm: {
        costCenter : null,
        transactionType: null,
        entryDate: null,
        entryDateName: null,
        entryTime: null,
        valueDate: null,
        valueDateName:null,
        tcuser: null,
        tcUti: null,
        uniqueLinkID: null,
        relatedTradeUti: null,
        accountName1: null,
        accountNumber1: null,
        accountCode1: null,
        accountType1: null,
        ccy1: null,
        amount1: null,
        cost1: null,
        description1: null,
        note1: null,
        accountName2: null,
        accountNumber2: null,
        accountCode2: null,
        accountType2: null,
        ccy2: null,
        amount2: null,
        cost2: null,
        description2: null,
        note2: null,
        tradeRef: null,
        entryType2: "Main Entry",
        // entryFrom: null,
        // entryTo: null,
        // entryType2: null,
        // valueFrom: null,
        // valueTo: null,
      },
      changedValuesForm:{
        costCenter : null,
        transactionType: null,
        entryDate: null,
        entryTime: null,
        valueDate: null,
        tcuser: null,
        tcUti: null,
        uniqueLinkId: null,
        relatedTradeUti: null,
        accountName1: null,
        accountNumber1: null,
        accountCode1: null,
        accountType1: null,
        ccy1: null,
        amount1: null,
        cost1: null,
        description1: null,
        note1: null,
        accountName2: null,
        accountNumber2: null,
        accountCode2: null,
        accountType2: null,
        ccy2: null,
        amount2: null,
        cost2: null,
        description2: null,
        note2: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      entryDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave4DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 4,
        },
      },
    };
  },
  props: {
      
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchTransfers"]),
    
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.viewSelectedTradeModal = false;
      this.actionsData = null
    },
    changeEntryDate() {
      let entryDate = new Date(this.MDYdateFormat(this.tradeForm.entryDate));

      //set settle date name
      this.tradeForm.entryDateName = this.weekday[entryDate.getDay()];
    },

    changeValueDate(){
      let valueDate = new Date(this.MDYdateFormat(this.tradeForm.valueDate));

      //set value date name
      this.tradeForm.valueDateName = this.weekday[valueDate.getDay()];
    },

    arrangeInputFormats() {
      if (this.tradeForm.amount1 != null) {
        this.tradeForm.amount1 = Number.parseFloat(this.tradeForm.amount1).toFixed(4)
      }
      if (this.tradeForm.amount2 != null) {
        this.tradeForm.amount2 = Number.parseFloat(this.tradeForm.amount2).toFixed(4)
      }
      if (this.tradeForm.cost1 != null && this.tradeForm.cost1 != null) {
        this.tradeForm.cost1 = Number.parseFloat(this.tradeForm.cost1).toFixed(4)
      }
      if (this.tradeForm.cost2 != null && this.tradeForm.cost1 != null) {
        this.tradeForm.cost2 = Number.parseFloat(this.tradeForm.cost2).toFixed(4)
      }
    },
    resetTradeModal() {
      this.viewSelectedTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null
      this.changedInputs = [];
      this.checkVerify = false
      this.ccy1Locked = false;
      this.ccy2Locked = false;
      this.cost1Enabled = false;
      this.cost2Enabled = false;
      this.oldCcy1 = null;
      this.checkVerify = false;
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },

    roundToTwo(val) {
      if (val != undefined && val > 0) {
        return Math.round(val * 100) / 100;
        // return +(Math.round(val + "e+2") + "e-2");
      }
    },
  },
  watch: {
    viewSelectedTradeModal: {
      handler: function(val, before) {
        if (val) {
          setTimeout(() => {
            if (this.changedInputs.length > 0) {
              // console.log(this.changedInputs)
              this.changedInputs.forEach((i) => {
                if (this.changedValuesForm.hasOwnProperty(_.camelCase(i.key))) {
                  let refKey = _.camelCase(i.key);
                  
                  if (this.$refs[refKey]) {
                    if(this.$refs[refKey].$el.classList.length > 0 || this.$refs[refKey].$el.classList.indexOf('v-select') == 1){
                      this.$refs[refKey].$el.classList.add('change_selectbox_style') // for v-select box
                      
                    }

                    this.$refs[refKey].$el.style = "color:orange";
                  }
                }
              });
            }
          }, 500);
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,
            transactionType:
              val.TransactionType != null ? val.TransactionType : null,
            entryDate:
              val.EntryDate != null ? this.dateFormat(val.EntryDate) : null,
            entryTime:
              val.EntryTime != null ? val.EntryTime  : null,
            valueDate:
              val.ValueDate != null ? this.dateFormat(val.ValueDate) : null,
            tcuser: val.TcUser != null ? val.TcUser : null,
            tcUti: val.TcUti != null ? val.TcUti : null,
            uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
            relatedTradeUti:
              val.RelatedTradeUti != null ? val.RelatedTradeUti : null,
            accountName1: val.AccountName1 != null ? val.AccountName1 : null,
            accountNumber1:
              val.AccountNumber1 != null ? val.AccountNumber1 : null,
            accountCode1: val.AccountCode1 != null ? val.AccountCode1 : null,
            accountType1: val.AccountType1 != null ? val.AccountType1 : null,
            ccy1: val.Ccy1 != null ? val.Ccy1 : null,
            amount1:
              val.Amount1 != null
                ? parseFloat(val.Amount1)
                : null,
            cost1:
              val.Cost1 != null ? parseFloat(val.Cost1) : null,
            description1: val.Description1 != null ? val.Description1 : null,
            note1: val.Note1 != null ? val.Note1 : null,
            accountName2: val.AccountName2 != null ? val.AccountName2 : null,
            accountNumber2:
              val.AccountNumber2 != null ? val.AccountNumber2 : null,
            accountCode2: val.AccountCode2 != null ? val.AccountCode2 : null,
            accountType2: val.AccountType2 != null ? val.AccountType2 : null,
            ccy2: val.Ccy2 != null ? val.Ccy2 : null,
            amount2:
              val.Amount2 != null
                ? parseFloat(val.Amount2)
                : null,
            cost2:
              val.Cost2 != null ? parseFloat(val.Cost2) : null,
            description2: val.Description2 != null ? val.Description2 : null,
            note2: val.Note2 != null ? val.Note2 : null,
            tradeRef: val.id != 0 ? val.id : 0,
            savedBy: val.SavedBy != null ? val.SavedBy : null,
            saveDate: val.SaveDate != null ? val.SaveDate : null,
            saveTime: val.SaveTime != null ? val.SaveTime : null,
            amendedBy: val.AmendedBy != null ? val.AmendedBy : null,
            amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
            amentmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
            amendmentReason:
              val.AmendmentReason != null ? val.AmendmentReason : null,
            canceled: 0,
            canceledBy: null,
            canceledDate: null,
            canceledTime: null,
            canceledReason: null,
          };

          this.changeEntryDate();
          this.changeValueDate();
          this.arrangeInputFormats();

          if (this.tradeForm.transactionType == "Internal Transfer") {
            if(!this.tradeForm.amount2 < 0){
              this.tradeForm.amount2 = this.tradeForm.amount2 * -1
            }

          }

        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.animate__animated {
  animation-fill-mode: none;
}
.entryDateName .input-group-append .input-group-text {
  color: red;
}
.valueDateName .input-group-append .input-group-text {
  color: red;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.change_selectbox_style {
  .vs__dropdown-toggle{
    .vs__selected-options{
      .vs__selected {
        color:orange
       }
    }
  }
}

</style>
