<template>
  <div>
    <b-card class="mb-1" :title="$t('transfers.titles.transfers')">
      
        <hr />
        <b-overlay :show="orderColumnsOverlay">
        <b-row class="mb-2">
          <b-col cols="12" xl="8" md="8" sm="12">
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
              <b-col cols="6" md="6" xl="6" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('transfers.cash_date')"
                    onClose="testClose"
                    v-model="cashRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getfilterCashDate()"
                    :disabled="
                      typeof lastSearchData != 'Object' && $Can('search_transfer')
                        ? !lastSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearCashDate()"
                      variant="success"
                      :disabled="
                        typeof lastSearchData != 'Object' && $Can('search_transfer')
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6" md="6" xl="6" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('transfers.value_date')"
                    onClose="testClose"
                    v-model="valueRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterValueDate"
                    :disabled="
                      typeof lastSearchData != 'Object' && $Can('search_transfer')
                        ? !lastSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearValueDate()"
                      variant="success"
                        :disabled="
                        typeof lastSearchData != 'Object' && $Can('search_transfer')
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </div>
            <hr class="mt-0" />
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
            <!-- Per Page -->
            <b-col cols="12" md="3" sm="4" class="d-flex align-items-center justify-content-start mb-md-0" v-if="visibleTransferInput">
              <label>Show</label>
              <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50 w-100"  />
              <label>entries</label>
            </b-col>
              <!-- filter status -->
              <b-col cols="12" md="3" xl="3" sm="4" class="d-flex align-items-center justify-content-start mb-md-0">
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="statusOptions"
                  v-model="treeStatus"
                  valueFormat="object"
                  :limit="5"
                  clearable
                  :placeholder="$t('transfers.transfers_selections')"
                  v-if="visibleTransferInput"
                  class="treeSelect"
                />
              </b-col>
              <!-- filter table columns -->
              <b-col cols="6" md="6" xl="6" sm="4" class="d-flex align-items-center justify-content-start mb-md-0">
                <!-- <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="tableColumnsOptions"
                  :clearable="false"
                  :searchable="true"
                  v-model="treeTablecolumns"
                  valueFormat="object"
                  :limit="4"
                  :placeholder="$t('transfers.get_selected_columns')"
                  v-if="visibleTransferInput"
                  class="treeSelect"
                /> -->
              </b-col>
              <!-- search in table -->
              <!-- <b-col cols="6" sm="6" xl="6" md="6">
                  <b-form-input
                    v-model="filter"
                    class="d-inline-block mr-1"
                    :placeholder="$t('general_text.search_table')"
                    v-if="allTransfersData.length > 0"
                  />
                </b-col> -->
            </div>
          </b-col>
          <b-col cols="12" xl="4" md="4" sm="12">
            <div class="d-flex" style="width: 100% !important;">
              <b-col v-if="$Can('search_transfer')" cols="6" md="6" sm="12">
                <b-button
                
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-info"
                  size="sm"
                  class="top-btns"
                  @click="searchForTradesModal"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("transfers.buttons.search_for_trades")
                  }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$Can('search_transfer')" cols="6" md="6" sm="12">
                <b-button
                

                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="top-btns"
                  @click="runLastSearch()"
                  :disabled="
                        typeof lastSearchData != 'Object' && $Can('search_transfer')
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("transfers.buttons.run_last_search")
                  }}</span>
                </b-button>
              </b-col>
            </div>
            <hr />
            <div class="d-flex mt-1" style="width: 100% !important;">
              <b-col v-if="$Can('add_transfer')" cols="6" xl="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="gradient-success"
                  class="top-btns"
                  @click="createNewTrade"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("transfers.buttons.enter_new_trade")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="3" xl="3" md="3" sm="12">
                <b-button
                  id="info-legend-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("transfers.buttons.legend_table")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-legend-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("transfers.legend_table.legend") }}
                  </template>
                  <b-table-simple hover small caption-top responsive bordered>
                    <b-tbody>
                      <b-tr>
                        <td style="width: 60%;">
                          {{ $t("transfers.legend_table.verified") }}
                        </td>
                        <td style="background-color: springgreen;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("transfers.legend_table.journal_created") }}
                        </td>
                        <td style="background-color: mediumpurple;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("transfers.legend_table.revers_initiated") }}
                        </td>
                        <td style="background-color: #FFFF99;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("transfers.legend_table.reversed") }}
                        </td>
                        <td style="background-color: coral;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("transfers.legend_table.cancelled") }}
                        </td>
                        <td style="background-color: #ffb8b8;"></td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-popover>
              </b-col>

                       <b-col cols="3" xl="3" md="3" sm="12">



                <b-button
                  id="info-export-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("equity_bond.buttons.export")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-export-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("equity_bond.buttons.export") }}
                  </template>

                  <b-button
                  size="sm"
                  class="top-btns"
                  @click="exportXlsx()"
                >
               
                  <span class="text-nowrap">Save as .xlsx</span>
                </b-button>

                <b-button
                  size="sm"
                  class="top-btns mt-1"
                  @click="exportCsv()"
                >
             
                  <span class="text-nowrap">Save as .csv</span>
                </b-button>
                  
                </b-popover>
              </b-col>
            </div>
          </b-col>
        </b-row>
          <!-- order columns overlay -->
          <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <p><strong id="form-confirm-label">{{ $t("general_messages.save_order_columns_message") }}</strong></p>

            <p class="mb-0" style="font-size:0.8rem;">{{ $t("general_messages.filter_columns_message") }}</p>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="tableColumnsOptions"
                  :clearable="false"
                  :searchable="true"
                  v-model="treeTablecolumns"
                  @select="selectTreeColumn"
                  @deselect="deSelectTreeColumn"
                  valueFormat="object"
                  :limit="9"
                  :placeholder="$t('transfers.get_selected_columns')"
                  v-if="visibleTransferInput"
                  class="treeSelect"
                />
              </b-col>
            <div class="d-flex justify-content-center mt-1">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="gradient-danger"
                class="mr-3"
                size="sm"
                @click="onCancelOrderColumn"
              >
              {{ $t("general_buttons.cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="gradient-warning"
                size="sm"
                @click="onSaveOrderColumns"
              >
              {{ $t("general_buttons.save_columns_order") }}
              </b-button>
              
            </div>
          </div>
        </template>
      </b-overlay>
      </b-card>

        <b-overlay :show="loading" rounded="sm" no-fade>
          <b-card>
        <div @contextmenu.prevent="$refs.contextMenu.open" v-if="allTransfersData.length > 0">
          <div  style="background-color:white;" :style="'height:' + (windowHeight - 550) + 'px'" id="table-container">

        <b-table
          :sticky-header="true"
          :no-border-collapse="true"
          hover
          :bordered="true"
          ref="reftransfersTable"
          class="mt-1"
          thead-class="animate__animated animate__fadeIn"
          :items="filteredTrades"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
          :tbody-tr-class="legendClass"
          caption-top
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortByDesc"
        >
        <!-- @row-clicked="onRowSelected" -->

         <!-- sort columns draggable -->
         <template slot="thead-top" slot-scope="data">
              <draggable v-model="tableColumns" tag="tr" id="draggableColumns" style="display: none;" class="animate__animated animate__fadeIn">
                <th v-for="header in tableColumns" :key="header.key" scope="col" style="white-space: nowrap;padding: 1rem;cursor:grab">{{ header.label }}</th>
              </draggable>
            </template>

              <!-- filter columns -->
              <template
              slot="top-row"
              slot-scope="{ fields }"
              v-if="showColumnsFilter"
            >
              <td :class="field.key == 'ID' ? 'sticky-column' : ''" v-for="field in tableColumns" :key="field.key">
                <v-select
                  v-if="field.key == 'TransactionType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                  :options="optTransactionTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <input
                  v-else-if="field.key == 'ID'"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="'ID_column_search_input'"

                  style="max-width: 75px;"
                />
                
                <input
                  v-else
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="field.key + ' column_search_input'"
                />
                <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
              </td>
            </template>


          <!-- Column: ID -->
          <template #cell(ID)="data">
            <div class="d-flex justify-content-center align-items-center">

            <b
              ><span class="font-weight-bold text-center">
                <strong style="text-decoration:underline;">{{ data.item.ID ? data.item.ID : "-" }}</strong>
              </span></b
            >

<div v-if="showMultiplecheckboks" style="margin-left:0.3em">
        <b-form-checkbox
          v-model="data.item.selectedTrade"
          @change="multiSelectRow(data.item.selectedTrade, data.item)"
        >
        </b-form-checkbox>

      </div>


            </div>

            <!-- trade controls -->
            <div>
              <b-button
                  :id="'trade-controls-' + data.item.ID.toString()"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                  @click="showTradeControlsPopover('trade-controls-' + data.item.ID.toString(),data.item)"
                >
                <i class="fa-solid fa-bars"></i>

                  
                </b-button>
                <b-popover
                  :target="'trade-controls-' + data.item.ID.toString()"
                  triggers="focus"
                  variant="primary"
                >
                  <template #title>
                    {{ $t('equity_bond.titles.trade_controls') }}
                  </template>

                  <b-button
                    v-if="$Can('verify_transfer') && seeVerifyButton"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    :title="$t('equity_bond.buttons.verify_trade')"
                    size="sm"
                    class="top-btns mt-1"
                    @click="verifySelectedTrade(data.item)"
                  >
                    

                    <span class="trades-font">
                      <feather-icon
                      icon="CheckSquareIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.verify_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('amend_transfer') && seeAmendButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-warning"
                    size="sm"
                    class="top-btns mt-1"
                    @click="amendUpdate(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EditIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.amend_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('journal_transfer') && seeJournalButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="createJournalEntries(data.item)"
                  >
                    
                    <span class="trades-font"><feather-icon
                      icon="ClipboardIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.create_Journal_entries")
                    }}</span>
                  </b-button>


                  <b-button
                    v-if="$Can('view_transfer')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewSelectedTrade(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.view_trade")
                    }}</span>
                  </b-button>


                  <b-button
                    v-if="$Can('copy_transfer') && seeCopyButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="copySelectedTrade(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="CopyIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.copy_trade")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('history_transfer') && seeAmendDetailsButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="getAmendDetails(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ListIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.get_amend_details")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('view_journals_transfer') && seeJorurnalEntriesButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewJournalEntries(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="EyeIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.view_journal_entries")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('initiate_transfer') && seeReversalInitiatedButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="initiateReversal(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="PlayIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.initiate_revesal")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('reverse_transfer') && seeReversedButton "
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="reverseEntry(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="CornerUpLeftIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.reverse_entry")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('undo_transfer') && seeUndoInitiatedButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-dark"
                    size="sm"
                    class="top-btns mt-1"
                    @click="undoInitiateReversal(data.item)"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="ChevronsLeftIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.undo_initiate_reversal")
                    }}</span>
                  </b-button>

                  <b-button
                    v-if="$Can('cancel_transfer') && seeCancelledButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-danger"
                    size="sm"
                    class="top-btns mt-1"
                    @click="cancelSelectedTrade(data.item)"
                    :disabled="data.item.Cancelled == 1"
                  >
                    
                    <span class="trades-font"> <feather-icon
                      icon="XSquareIcon"
                      style="margin-right: 0.3rem;"
                    />{{
                      $t("equity_bond.buttons.cancel_trade")
                    }}</span>
                  </b-button>
                </b-popover>
            </div>

          </template>

          <!-- Column: TransactionType -->
          <template #cell(TransactionType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TransactionType ? data.item.TransactionType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryDate -->
          <template #cell(EntryDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryDate ? dateFormat(data.item.EntryDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryTime -->
          <template #cell(EntryTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryTime ? data.item.EntryTime : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ValueDate -->
          <template #cell(ValueDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ValueDate ? dateFormat(data.item.ValueDate) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUser -->
          <template #cell(TcUser)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUser ? data.item.TcUser : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUti -->
          <template #cell(TcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountName1 -->
          <template #cell(AccountName1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName1 ? data.item.AccountName1 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountNumber1 -->
          <template #cell(AccountNumber1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber1 ? data.item.AccountNumber1 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCode1 -->
          <template #cell(AccountCode1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountCode1 ? data.item.AccountCode1 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountType1 -->
          <template #cell(AccountType1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountType1 ? data.item.AccountType1 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ccy1 -->
          <template #cell(Ccy1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ccy1 ? data.item.Ccy1 : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Amount1 -->
          <template #cell(Amount1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Amount1 ? formatPrice(data.item.Amount1, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cost1 -->
          <template #cell(Cost1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Cost1 ? formatPrice(data.item.Cost1, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Description1 -->
          <template #cell(Description1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Description1 ? data.item.Description1 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Note1 -->
          <template #cell(Note1)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Note1 ? data.item.Note1 : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountName2 -->
          <template #cell(AccountName2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName2 ? data.item.AccountName2 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountNumber2 -->
          <template #cell(AccountNumber2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber2 ? data.item.AccountNumber2 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCode2 -->
          <template #cell(AccountCode2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountCode2 ? data.item.AccountCode2 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountType2 -->
          <template #cell(AccountType2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountType2 ? data.item.AccountType2 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Ccy2 -->
          <template #cell(Ccy2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Ccy2 ? data.item.Ccy2 : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Amount2 -->
          <template #cell(Amount2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Amount2 ? formatPrice(data.item.Amount2, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cost2 -->
          <template #cell(Cost2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Cost2 ? formatPrice(data.item.Cost2, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Description2 -->
          <template #cell(Description2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Description2 ? data.item.Description2 : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Note2 -->
          <template #cell(Note2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Note2 ? data.item.Note2 : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: RelatedTradeUti -->
          <template #cell(RelatedTradeUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.RelatedTradeUti ? data.item.RelatedTradeUti : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: UniqueLinkId -->
          <template #cell(UniqueLinkId)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryType2 & Main Entry -->
          <template #cell(EntryType2)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ "Main Entry" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SavedBy -->
          <template #cell(SavedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SavedBy ? data.item.SavedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveDate -->
          <template #cell(SaveDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveDate != null
                    ? dateFormat(data.item.SaveDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveTime -->
          <template #cell(SaveTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveTime != null
                    ? data.item.SaveTime
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendedBy -->
          <template #cell(AmendedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendedBy ? data.item.AmendedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentDate -->
          <template #cell(AmendmentDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentDate != null
                    ? dateFormat(data.item.AmendmentDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentTime -->
          <template #cell(AmendmentTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentTime != null
                    ? data.item.AmendmentTime
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AmendmentReason -->
          <template #cell(AmendmentReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AmendmentReason ? data.item.AmendmentReason : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Cancelled -->
          <template #cell(Cancelled)="data">
            <b
              ><span class="font-weight-bold text-white">
                <strong>{{
                  data.item.Cancelled == 1 ? "Cancelled" : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelledBy -->
          <template #cell(CancelledBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelledBy ? data.item.CancelledBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelDate -->
          <template #cell(CancelDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelDate != null
                    ? dateFormat(data.item.CancelDate)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelTime -->
          <template #cell(CancelTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelTime != null
                    ? data.item.CancelTime
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: CancelReason -->
          <template #cell(CancelReason)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.CancelReason ? data.item.CancelReason : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- trade controls section ------------------------------------------------------>
          <!-- <template #row-details="row">
            <b-card :title="$t('transfers.titles.trade_controls')">
              <b-col cols="12">
                <hr />
              </b-col>

              <div
                class="d-flex mb-1"
                style="max-width:1300px!important;white-space:nowrap;overflow-x:scroll;"
              >
                <b-col v-if="$Can('verify_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-success"
                    :title="$t('transfers.buttons.verify_trade')"
                    size="sm"
                    class="top-btns"
                    @click="verifySelectedTrade(row.item)"
                  >
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />

                    <span class="trades-font">{{
                      $t("transfers.buttons.verify_trade")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('amend_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-warning"
                    size="sm"
                    class="top-btns"
                    @click="amendUpdate(row.item)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.amend_trade")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('journal_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="top-btns"
                    @click="createJournalEntries(row.item)"
                  >
                    <feather-icon
                      icon="ClipboardIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.create_Journal_entries")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('view_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="viewSelectedTrade(row.item)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.view_trade")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('copy_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="copySelectedTrade(row.item)"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.copy_trade")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('history_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="getAmendDetails(row.item)"
                  >
                    <feather-icon
                      icon="ListIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.get_amend_details")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('view_journals_transfer')" cols="2" md="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-secondary"
                    size="sm"
                    class="top-btns"
                    @click="viewJournalEntries(row.item)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.view_journal_entries")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('initiate_transfer')" cols="2" md="2" xl="2" lg="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    size="sm"
                    class="top-btns"
                    @click="initiateReversal(row.item)"
                  >
                    <feather-icon
                      icon="PlayIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.initiate_revesal")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('reverse_transfer')" cols="2" md="2" xl="2" lg="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    size="sm"
                    class="top-btns"
                    @click="reverseEntry(row.item)"
                  >
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.reverse_entry")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('undo_transfer')" cols="2" md="2" xl="2" lg="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-dark"
                    size="sm"
                    class="top-btns"
                    @click="undoInitiateReversal(row.item)"
                  >
                    <feather-icon
                      icon="ChevronsLeftIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.undo_initiate_reversal")
                    }}</span>
                  </b-button>
                </b-col>
                <b-col v-if="$Can('cancel_transfer')" cols="2" md="2" xl="2" lg="2" sm="12">
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="flat-danger"
                    size="sm"
                    class="top-btns"
                    @click="cancelSelectedTrade(row.item)"
                    :disabled="row.item.Cancelled == 1"
                  >
                    <feather-icon
                      icon="XSquareIcon"
                      size="24"
                      style="margin-bottom: 0.3rem;"
                    />
                    <span class="trades-font">{{
                      $t("transfers.buttons.cancel_trade")
                    }}</span>
                  </b-button>
                </b-col>
              </div>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-card>
          </template> -->
        </b-table>
        </div>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
              :disabled="showOrderColumns"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkboks"
                  : "Hide multiple checkboks"
              }}</span>
            </b-link>
          </li>
          <li v-if="!showOrderColumns">
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('orderTableColumns')"
            >
            <i class="fa-solid fa-arrow-up-wide-short"></i>
              <span class="ml-75">{{ 'Sort columns'
              }}</span>
            </b-link>
          </li>
          <li
          v-if="selectedMultipleTrades.length > 0"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon
                icon="CopyIcon"
                size="16"
              />
              <span class="ml-75">{{ $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>

        <div class="mx-2" v-if="allTransfersData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="allTransfersData.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{ $t("general_title.not_found_data") }}</strong>
                <br />
                {{ $t("transfers.messages.not_found_transfers_data") }}</span
              >
            </div>
          </b-alert>
        </div>
      
      {{ /*search for trades modal*/ }}


      <SearchTrades
      
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"


      ref="searchTrades" @searchedData="getSearchedData" />

      {{ /*View Selected Trade for trades modal*/ }}
      <ViewSelectedTrade
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
      
      ref="ViewSelectedTrade" />

      {{ /*Enter New Trade for trades modal*/ }}
      <EnterNewTrade
        ref="refEnterNewTrade"
        :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"

        @createdtransfers="createdtransfers"
      />
      {{ /*Verify selected Trade for trades modal*/ }}
      <VerifyTrade
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
      
      
      ref="refVerifyTrade" @verified="verifiedTrade" />
      {{ /*Amend selected Trade for trades modal*/ }}
      <AmendSelectedTrade
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refAmendTrade"
        @updatedData="getUpdatedAmendData"
      />
      {{ /*Copy selected Trade for trades modal*/ }}
      <CopySelectedTrade
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refCopyTrade"
        @copiedtransfers="copiedtransfers"
      />
      {{ /*Create Journal selected Trade for trades modal*/ }}
      <CreateJournal
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refCreateJournalTrade"
        @createdJournalEntryArray="createdJournalEntryArray"
      />
      {{ /*View Journal selected Trade for trades modal*/ }}
      <ViewJournalEntries
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refViewJournalEntries"
        @createdJournalEntry="createdJournalEntry"
      />
      {{ /*Inititate reversal Trade for trades modal*/ }}
      <InitiateReversal
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refInitiateReversal"
        @reversaledTrade="reversaledTrade"
      />
      <ReverseEntry
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
      ref="refReverseEntry" @reversedEntry="reversedEntry" />
      {{ /*Undo selected Trade for trades modal*/ }}
      <UndoInitiateReversal
      :opt-counter-parties.sync = "optCounterParties"
        :opt-clients.sync = "optClients"
        :opt-cost-centers.sync = "optCostCenters"
        :searched-clients.sync = "searchedClients"
        :searched-counter-parties.sync = "searchedCounterparties"
        ref="refUndoInitiateReversal"
        @undoInitiate="undoInitiate"
      />
    </b-card>
  </b-overlay>
    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.failes_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(Tcuti)="data">
                  <strong>{{ data.item.Tcuti }}</strong>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>


  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import transfersStoreModule from "./transfersStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState, mapGetters, mapActions } from "vuex";
import SearchTrades from "./SearchTrades.vue";
import ViewSelectedTrade from "./ViewSelectedTrade.vue";
import EnterNewTrade from "./EnterNewTrade.vue";
import VerifyTrade from "./VerifyTrade.vue";
import AmendSelectedTrade from "./AmendSelectedTrade.vue";
import CopySelectedTrade from "./CopySelectedTrade.vue";
import CreateJournal from "./CreateJournal.vue";
import ViewJournalEntries from "./ViewJournalEntries.vue";
import InitiateReversal from "./InitiateReversal.vue";
import ReverseEntry from "./ReverseEntry.vue";
import UndoInitiateReversal from "./UndoInitiateReversal.vue";
import VueHorizontal from "vue-horizontal";
import VueContext from "vue-context";
import { debounce } from "lodash";
import draggable from 'vuedraggable'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    SearchTrades,
    ViewSelectedTrade,
    EnterNewTrade,
    VerifyTrade,
    AmendSelectedTrade,
    CopySelectedTrade,
    CreateJournal,
    ViewJournalEntries,
    InitiateReversal,
    ReverseEntry,
    UndoInitiateReversal,
    Treeselect,
    VueHorizontal,
    VueContext,
    draggable
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const TRANSFERS_APP_STORE_MODULE_NAME = "transfers";
    // Register module
    if (!store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
      store.registerModule(
        TRANSFERS_APP_STORE_MODULE_NAME,
        transfersStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TRANSFERS_APP_STORE_MODULE_NAME))
        store.unregisterModule(TRANSFERS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {

    this.getOrderTransferColumns()
    //option status
 

    let treeOptionsStatus = [
      {
        id: 1000000,
        label: this.$t("transfers.status"),
        children: [],
      },
    ];
    this.transfersStatusOptions.forEach((element) => {
      let setStatus = {
        id: element.text,
        label: element.text,
        value: element.text,
        is: "status",
      };
      treeOptionsStatus[0].children.push(setStatus);
    });

    this.statusOptions = treeOptionsStatus;
    //************************** */

    this.getLast7DaysTrades()
    this.getAllAccounts();
    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);



  },
  beforeDestroy() {
window.removeEventListener("resize", this.debouncedGetWindowHeight);  
},

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  data() {
    const transfersStatusOptions = [
      { text: "Verified" },
      { text: "Journal Created" },
      { text: "Revers Inititated" },
      { text: "Reversed" },
      { text: "Cancelled" },
    ];

    const transfersTableColumns = [
      // { key: "show_details", label: "" },

      {
        stickyColumn: true,
        key: "ID",
        label: "ID",
        // thClass: "text-left",
        tdClass: "text-center ID_background",
        thClass: "sticky-column",
        thStyle: 'width: 50px',
        sortable: true,
        class: "text-start table-padding sticky-column" ,
        variant: "primary",
      },
      {
        key: "TcUti",
        label: this.$t("transfers.table.sfc_uti"),
        class: "text-center table-padding",
        sortable: true,
      },
      {
        key: "TransactionType",
        label: this.$t("transfers.table.transaction_type"),
        class: "text-center table-padding",
      },
      {
        key: "EntryDate",
        label: this.$t("transfers.table.entry_date"),
        class: "text-center table-padding text-nowrap",
      },
      {
        key: "EntryTime",
        label: this.$t("transfers.table.entry_time"),
        class: "text-center table-padding",
      },
      {
        key: "ValueDate",
        label: this.$t("transfers.table.value_date"),
        class: "text-center table-padding text-nowrap",
      },
      {
        key: "TcUser",
        label: this.$t("transfers.table.operations_user"),
        class: "text-center table-padding",
        sortable: true,
      },

      
      {
        key: "AccountName1",
        label: this.$t("transfers.table.account_name1"),
        class: "text-center table-padding",
      },
      {
        key: "AccountNumber1",
        label: this.$t("transfers.table.account_number1"),
        class: "text-center table-padding",
      },

      {
        key: "AccountCode1",
        label: this.$t("transfers.table.account_code1"),
        class: "text-center table-padding",
      },

      {
        key: "AccountType1",
        label: this.$t("transfers.table.account_type1"),
        class: "text-center table-padding",
        sortable: true,
      },
      {
        key: "Ccy1",
        label: this.$t("transfers.table.ccy1"),
        class: "text-center table-padding",
      },

      {
        key: "Amount1",
        label: this.$t("transfers.table.amount1"),
        class: "text-center table-padding",
        sortable: true,
      },
      {
        key: "Cost1",
        label: this.$t("transfers.table.cost1"),
        class: "text-center table-padding",
      },
      {
        key: "Description1",
        label: this.$t("transfers.table.description1"),
        class: "text-center table-padding",
      },
      {
        key: "Note1",
        label: this.$t("transfers.table.note1"),
        class: "text-center table-padding",
      },
      {
        key: "AccountName2",
        label: this.$t("transfers.table.account_name2"),
        class: "text-center table-padding",
      },
      {
        key: "AccountNumber2",
        label: this.$t("transfers.table.account_number2"),
        class: "text-center table-padding",
      },

      {
        key: "AccountCode2",
        label: this.$t("transfers.table.account_code2"),
        class: "text-center table-padding",
      },

      {
        key: "AccountType2",
        label: this.$t("transfers.table.account_type2"),
        class: "text-center table-padding",
        sortable: true,
      },
      {
        key: "Ccy2",
        label: this.$t("transfers.table.ccy2"),
        class: "text-center table-padding",
      },

      {
        key: "Amount2",
        label: this.$t("transfers.table.amount2"),
        class: "text-center table-padding",
        sortable: true,
      },
      {
        key: "Cost2",
        label: this.$t("transfers.table.cost2"),
        class: "text-center table-padding",
      },
      {
        key: "Description2",
        label: this.$t("transfers.table.description2"),
        class: "text-center table-padding",
      },
      {
        key: "Note2",
        label: this.$t("transfers.table.note2"),
        class: "text-center table-padding",
      },
      {
        key: "RelatedTradeUti",
        label: this.$t("transfers.table.related_trade_uti"),
        class: "text-center table-padding",
      },
      {
        key: "UniqueLinkId",
        label: this.$t("transfers.table.unique_link_id"),
        class: "text-center table-padding",
      },
      {
        key: "EntryType2",
        label: this.$t("transfers.table.entry_type"),
        class: "text-center table-padding",
      },
      {
        key: "SavedBy",
        label: this.$t("transfers.table.saved_by"),
        class: "text-center table-padding",
      },
      {
        key: "SaveDate",
        label: this.$t("transfers.table.save_date"),
        class: "text-start table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "SaveTime",
        label: this.$t("transfers.table.save_time"),
        class: "text-center table-padding",
      },
      {
        key: "AmendedBy",
        label: this.$t("transfers.table.amended_by"),
        class: "text-center table-padding",
      },
      {
        key: "AmendmentDate",
        label: this.$t("transfers.table.amendment_date"),
        class: "text-center table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "AmendmentTime",
        label: this.$t("transfers.table.amendment_time"),
        class: "text-center table-padding",
      },
      {
        key: "AmendmentReason",
        label: this.$t("transfers.table.amendment_reason"),
        class: "text-center table-padding",
      },

      {
        key: "Cancelled",
        label: this.$t("transfers.table.cancelled"),
        class: "text-center table-padding",
      },
      {
        key: "CancelledBy",
        label: this.$t("transfers.table.cancelled_by"),
        class: "text-center table-padding",
      },
      {
        key: "CancelDate",
        label: this.$t("transfers.table.canceled_date"),
        class: "text-center table-padding text-nowrap",
        sortable: true,
      },
      {
        key: "CancelTime",
        label: this.$t("transfers.table.cancelled_time"),
        class: "text-center table-padding",
      },
      {
        key: "CancelReason",
        label: this.$t("transfers.table.cancelled_reason"),
        class: "text-center table-padding",
      },
      // {
      //   stickyColumn: true,
      //   key: "TradeActions",
      //   label: "Actions",
      //   class: "text-center",
      //   isRowHeader: true,
      //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
      // },
    ];

    return {
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.id"),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "Tcuti",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.tcUti"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 30%",
        },
        {
          key: "reason",
          label: this.$t("multiCheckBoxTitles.failed_proceses_table_columns.reason"),
          sortable: false,
          class: "text-start",
          thStyle: "width: 50%",
        },
      ],
      windowHeight: window.innerHeight,
      userData: JSON.parse(localStorage.getItem("userData")),

      allTransfersData: [],
      alltransfersNotFilterData: [],
      visibleTransferInput: false,
      sortByDesc: true,
      sortBy: "ID",
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: null,
      tableOrjinalColumns: null,
      transfersTableColumns:transfersTableColumns,
      optTransactionTypes: [
      { title: "all", value: "" },
      { title: "Transfer In", value: "Transfer In" },
      { title: "Transfer Out", value: "Transfer Out" },
      { title: "Internal Transfer", value: "Internal Transfer" },
      { title: "Fee", value: "Fee" },
      { title: "Coupon Payment", value: "Coupon Payment" },
      { title: "Dividend Payment", value: "Dividend Payment" },
      { title: "Overnight Interest", value: "Overnight Interest" },
      { title: "Realised PnL", value: "Realised PnL" },
      ],
      filters: {
        TransactionType: "",
        TcUti: "",
        TcUser: "",
      },
      perPage: localStorage.getItem('transferTradesShow') ?? 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      filter: null,
      loading: false,
      filterValueDate: null,
      filterCashDate: null,
      canceledReasonText: null,
      statusOptions: [],
      tableColumnsOptions: [],
      transfersStatusOptions: transfersStatusOptions,
      treeStatus: null,
      treeTablecolumns: [],
      valueRangeDate: null,
      filterValueDateDateStart: null,
      filterValueDateDateEnd: null,
      cashRangeDate: null,
      filterCashDateStart: null,
      filterCashDateEnd: null,
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode: "range",
      },

      optClients : [],
      optCounterParties : [],
      searchedClients : [],
      searchedCounterparties : [],
      optCostCenters : [],
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      showOrderColumns:false,
      orderColumnsOverlay:false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeAmendDetailsButton : false,
      seeAmendButton : false,
      seeCopyButton : false,
      seeVerifyButton : false,
      seeJournalButton : false,
      seeJorurnalEntriesButton : false,
      seeReversalInitiatedButton : false,
      seeUndoInitiatedButton : false,
      seeReversedButton : false,
      seeCancelledButton:false,
      tradeForm: {
        costCenter:null,
        transactionType: null,
        entryDate: null,
        entryTime: null,
        valueDate: null,
        tcuser: null,
        tcUti: null,
        uniqueLinkID: null,
        relatedTradeUti: null,
        accountName1: null,
        accountNumber1: null,
        accountCode1: null,
        accountType1: null,
        ccy1: null,
        amount1: null,
        cost1: null,
        description1: null,
        note1: null,
        accountName2: null,
        accountNumber2: null,
        accountCode2: null,
        accountType2: null,
        ccy2: null,
        amount2: null,
        cost2: null,
        description2: null,
        note2: null,
        entryFrom: null,
        entryTo: null,
        tradeRef: null,
        entryType2: null,
        valueFrom: null,
        valueTo: null,
      },

    };
  },
  computed: {
    ...mapState({
      lastSearchData: (state) => state.runLastTransfersSearchData,
      userColumnsData: (state) => state.usersOrderColumnsTransferData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["getLastSearchTransfers","getUserOrderColumnsTransfer"]),

    filteredTrades() {
      if(this.allTransfersData.length > 0) {
        const filtered = this.allTransfersData.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0
        ? filtered
        : [
            {
            TransactionType: "",
            TcUti: "",
            TcUser: "",
          }
          ];
      }
      
    },

    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    ...mapActions(["saveOrderColumnsTransfer","getOrderColumnsTransfer"]),
    getWindowHeight() {

      this.windowHeight = window.innerHeight;
      this.updateStickyHeaderHeight()

      },

      updateStickyHeaderHeight() {
        this.$nextTick(() => {
              const headerElements = document.getElementsByClassName("b-table-sticky-header");
              if (headerElements.length > 0) {
                const newHeight = window.innerHeight - 550;
                this.stickyHeaderStyle = `height: ${newHeight}px`;
              }
      });
      },

    getLast7DaysTrades() {
      this.$checkAccessRight("CashTransactions", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("transfers/getLast7DaysTransferTrades")
            .then((res) => {
              this.allTransfersData = [];
                  if (res.data.length > 0) {
                    this.visibleTransferInput = true;
                  } else {
                    this.visibleTransferInput = false;
                  }
                  this.totalRows = res.data.length;
                  this.allTransfersData = res.data;
                  this.alltransfersNotFilterData = res.data;
                  this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    showTradeControlsPopover(targetTrade,trade){
      if(trade){

        // set default
        this.seeAmendDetailsButton = false
        this.seeVerifyButton = false
        this.seeAmendButton = false
        this.seeCopyButton = false
        this.seeJournalButton = false
        this.seeJorurnalEntriesButton = false
        this.seeReversalInitiatedButton = false
        this.seeUndoInitiatedButton = false
        this.seeReversedButton = false
        this.seeCancelledButton = false


        // set suitable flow buttons

        // see amend details
        if(trade.AmendedBy !=null && trade.AmendmentDate != null && trade.AmendmentTime != null &&  trade.Cancelled == 0){
            this.seeAmendDetailsButton = true
          }


          // see amend trade button
          if(trade.JournalCreated == 0 && trade.Cancelled == 0){
            this.seeAmendButton = true
          }
          // see copy trade button
          if(trade.Cancelled == 0){
            this.seeCopyButton = true
          }

          // see verify
          if(trade.Verified == 0 && trade.Cancelled == 0){
            this.seeVerifyButton = true
          }
          // see journal
          if(trade.Verified == 1 && trade.JournalCreated == 0 && trade.Cancelled == 0){
                this.seeJournalButton = true
          }

          // see trade journalEntries
          if(trade.Verified == 1 && trade.JournalCreated == 1 && trade.Cancelled == 0){
                this.seeJorurnalEntriesButton = true
          }

          // see revers initiated
          if(trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 0 && trade.Cancelled == 0 ){
                this.seeReversalInitiatedButton = true
          }

          // see undo initiated
          if(trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 1 && trade.Reversed == 0 && trade.Cancelled == 0 ){
                this.seeUndoInitiatedButton = true
          }

          // see reversed
          if(trade.Verified == 1 && trade.JournalCreated == 1 && trade.ReversalInitiated == 1 && trade.Reversed == 0 && trade.Cancelled == 0 ){
                this.seeReversedButton = true
          }

          // if cancelled trade it can not do
          if(trade.CancelDate == null && trade.Cancelled == 0 && trade.CancelTime == null){
                this.seeCancelledButton = true
          }
      }

      this.$root.$emit('bv::show::popover', targetTrade)
    },

    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
        }

      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];
    

        this.tableColumns[1].class = this.showMultiplecheckboks
          ? "text-center"
          : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allTransfersData, (v) => (v.selectedTrade = false));
      }
      if(click == "orderTableColumns"){
        this.showOrderColumns = !this.showOrderColumns
        this.orderColumnsOverlay = true
        // hide main columns
        this.$refs.reftransfersTable.$el.classList.add("hide-rows")

        // visible draggable order columns
        var draggableColumns = document.getElementById("draggableColumns")
        draggableColumns.style.display = ""
      }
    },
    getOrderTransferColumns(){

    this.getOrderColumnsTransfer().then((res) => {
      // get order user columns
      if (this.userColumnsData.find((data) => data.user == this.user.name)) {
        let orderedColumnsData = this.getUserOrderColumnsTransfer
        this.tableColumns = orderedColumnsData
        this.tableOrjinalColumns = this.transfersTableColumns
        
      } else {
      
        this.tableColumns = this.transfersTableColumns
        this.tableOrjinalColumns = this.transfersTableColumns
      }
      
      //******************************* */

        // tree columns options
        let treeColumnsOption = [
      {
        id: 1000000,
        label: this.$t("transfers.columns"),
        children: [],
      },
    ];

    this.tableOrjinalColumns.forEach((element, index) => {
      if (element.key != "ID") {
        var setColumn = {
          id: index,
          label: element.key,
          value: element.key,
          is: "column",
        };
        treeColumnsOption[0].children.push(setColumn);

        if(this.tableColumns.find((column) => column.key === element.key)){
          this.treeTablecolumns.push(setColumn);
        }
      }
      
    });
    this.tableColumnsOptions = treeColumnsOption;

    //this.treeTablecolumns = treeColumnsOption[0].children;
    //******************* */

    }).catch((error) => {
      this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
      console.log(error);
    });
    },
    onSaveOrderColumns(){
    if(this.tableColumns && this.tableColumns.length > 0){
        this.saveOrderColumnsTransfer(this.tableColumns).then((res) => {
          // visible main columns
        this.$refs.reftransfersTable.$el.classList.remove("hide-rows");
        // hide draggable order columns
        var draggableColumns = document.getElementById("draggableColumns");
        draggableColumns.style.display = "none";
        this.showOrderColumns = false;
        this.orderColumnsOverlay = false
        this.successOrderColumnsMessage()
        }).catch((error) => {
          console.log(error);
        });
    } else {
      this.wrongToastMessage(this.$t("general_messages.columns_data_notfound"));
    }
    },
    onCancelOrderColumn(){
    // visible main columns
    this.$refs.reftransfersTable.$el.classList.remove("hide-rows");
    // hide draggable order columns
    var draggableColumns = document.getElementById("draggableColumns");
    draggableColumns.style.display = "none";
    this.showOrderColumns = false;
    this.orderColumnsOverlay = false;
    },
    selectTreeColumn(node){

    if(this.tableOrjinalColumns.find((c) => c.key === node.value)){
      let columnIndex = this.tableOrjinalColumns.findIndex((i) => i.key === node.value)
      let columnData = this.tableOrjinalColumns.find((c) => c.key === node.value)
      this.tableColumns.splice(columnIndex,0,columnData)
    }        

    },
    deSelectTreeColumn(node){
    if(this.tableColumns.find((c) => c.key === node.value)){
      let columnIndex = this.tableColumns.findIndex((i) => i.key === node.value)
      this.tableColumns.splice(columnIndex,1)
    }  
    },

    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          ID: trade.ID,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Create Journal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Revers Inititated") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Reversed") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Undo Initiate Reversal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Cancel") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (status == "copyTrades") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (trades.length > 0 && status) {
        if(status == "copyTrades"){
        this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
        this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    getAllAccounts() {
      store
        .dispatch("transfers/getAccountCostCenter",{type:'Transfers'})
        .then((res) => {
          this.optClients = [];
          this.optCounterParties = [];
          this.searchedClients = res.data.clients;
          this.searchedCounterparties = res.data.clients;
          this.optCostCenters = [];
          this.optCostCenters = res.data.cost_centers;
          this.$refs.refEnterNewTrade.tradeForm.costCenter = res.data.default_cost_center;
          this.$refs.refAmendTrade.tradeForm.costCenter = res.data.default_cost_center;
          if (res.data.clients.length > 0) {
            res.data.clients.forEach((data) => {
              this.optClients.push(data);
            });

            res.data.clients.forEach((data) => {
              this.optCounterParties.push(data);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initiateReversal(item) {
      this.$checkAccessRight("CashTransactions", "InitiateReversal").then(
        (res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
              if (item.ID != 0) {
                this.checkJournalCreated("InitiateReversal", item.TcUti).then(
                  (res) => {
                    if (res.data.info == true) {
                      this.checkEntryReversed("Initiate", item.TcUti).then(
                        (res) => {
                          if (res.data.info == true) {
                            this.checkReversalInitiated(
                              "InitiateReversal",
                              item.TcUti
                            ).then((res) => {
                              if (res.data.info == true) {
                                store
                                  .dispatch(
                                    "transfers/getAmendTransferInitialise",
                                    {
                                      auth: this.user,
                                      tradeId: item.ID,
                                      tcUti: item.TcUti,
                                    }
                                  )
                                  .then((res) => {
                                    if (res.data.info == "Pass") {


                                      if (this.getChoice()) {



                                        
                                      this.$refs.refInitiateReversal.fetchedTradeData =
                                        res.data.trade;

                                      //set timeline datas
                                      let actionsArray = [];
                                      if (
                                        res.data.trade.SavedBy != null &&
                                        res.data.trade.SavedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Saved",
                                          user: res.data.trade.SavedBy ?? "",
                                          date:
                                            res.data.trade.SaveDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.SaveTime.split(
                                              " "
                                            )[1],
                                          reason: "-",
                                        });
                                      }

                                      if (
                                        res.data.trade.AmendedBy != null &&
                                        res.data.trade.AmendedBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Amended",
                                          user: res.data.trade.AmendedBy ?? "",
                                          date:
                                            res.data.trade.AmendmentDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.AmendmentTime.split(
                                              " "
                                            )[1],
                                          reason:
                                            res.data.trade.AmendmentReason,
                                        });
                                      }
                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .VerificationUser != null &&
                                          res.data.actions[0]
                                            .VerificationUser != ""
                                        ) {
                                          this.$refs.refInitiateReversal.checkVerify = true;
                                          actionsArray.push({
                                            type: "Verified",
                                            user:
                                              res.data.actions[0]
                                                .VerificationUser ?? "",
                                            date:
                                              res.data.actions[0]
                                                .VerificationDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].JournalUser !=
                                            null &&
                                          res.data.actions[0].JournalUser != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Journal Created",
                                            user:
                                              res.data.actions[0].JournalUser ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .JournalDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (
                                        res.data.trade.CancelledBy != null &&
                                        res.data.trade.CancelledBy != ""
                                      ) {
                                        actionsArray.push({
                                          type: "Canceled",
                                          user:
                                            res.data.trade.CancelledBy ?? "",
                                          date:
                                            res.data.trade.CancelDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.CancelTime.split(
                                              " "
                                            )[1],
                                          reason: res.data.trade.CancelReason,
                                        });
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != null &&
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversal Initiated",
                                            user:
                                              res.data.actions[0]
                                                .ReversalInitiatedBy ?? "",
                                            date:
                                              res.data.actions[0]
                                                .ReversalInitiatedDateTime,
                                            reason:
                                              res.data.actions[0]
                                                .ReversalInitiatedReason,
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].ReversedBy !=
                                            null &&
                                          res.data.actions[0].ReversedBy != ""
                                        ) {
                                          actionsArray.push({
                                            type: "Reversed",
                                            user:
                                              res.data.actions[0].ReversedBy ??
                                              "",
                                            date:
                                              res.data.actions[0]
                                                .ReversedDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      this.$refs.refInitiateReversal.actionsData = actionsArray;
                                      this.$refs.refInitiateReversal.initiateReversalTradeModal = true;




                   
                                      } else {
                                        let verifyList = [];
                                        verifyList.push(item);
                                        this.tradeMultipleChangeStatusOpr(
                                          1,
                                          verifyList,
                                          "Revers Inititated"
                                        );
                    }





                                    } else {
                                      this.notFoundMessage();
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else {
                                this.checkReversalInitiatedMessage(
                                  res.data.message
                                );
                              }
                            });
                          } else {
                            this.checkEntryReversedMessage(res.data.message);
                          }
                        }
                      );
                    } else {
                      this.checkJournalCreatedMessage(res.data.message);
                    }
                  }
                );
              } else {
                this.tradeIDDoesntZeroMessage();
              }
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    reverseEntry(item) {
      this.$checkAccessRight("CashTransactions", "Reverse").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.checkJournalCreated("Reverse", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  this.checkEntryReversed("Reverse", item.TcUti).then((res) => {
                    if (res.data.info == true) {
                      this.checkReversalInitiated("Reverse", item.TcUti).then(
                        (res) => {
                          if (res.data.info == true) {
                            store
                              .dispatch("transfers/getTransferReverseEntry", {
                                auth: this.user,
                                tradeId: item.ID,
                                tcUti: item.TcUti,
                              })
                              .then((res) => {
                                if (res.data.info == "Pass") {


                                  if (this.getChoice()) {





                                    
                                  this.$refs.refReverseEntry.fetchedTradeData =
                                    res.data.trade;

                                  //set timeline datas
                                  let actionsArray = [];
                                  if (
                                    res.data.trade.SavedBy != null &&
                                    res.data.trade.SavedBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Saved",
                                      user: res.data.trade.SavedBy ?? "",
                                      date:
                                        res.data.trade.SaveDate.split(" ")[0] +
                                        " " +
                                        res.data.trade.SaveTime.split(" ")[1],
                                      reason: "-",
                                    });
                                  }

                                  if (
                                    res.data.trade.AmendedBy != null &&
                                    res.data.trade.AmendedBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Amended",
                                      user: res.data.trade.AmendedBy ?? "",
                                      date:
                                        res.data.trade.AmendmentDate.split(
                                          " "
                                        )[0] +
                                        " " +
                                        res.data.trade.AmendmentTime.split(
                                          " "
                                        )[1],
                                      reason: res.data.trade.AmendmentReason,
                                    });
                                  }
                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].VerificationUser !=
                                        null &&
                                      res.data.actions[0].VerificationUser != ""
                                    ) {
                                      this.$refs.refReverseEntry.checkVerify = true;
                                      actionsArray.push({
                                        type: "Verified",
                                        user:
                                          res.data.actions[0]
                                            .VerificationUser ?? "",
                                        date:
                                          res.data.actions[0]
                                            .VerificationDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].JournalUser != null &&
                                      res.data.actions[0].JournalUser != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Journal Created",
                                        user:
                                          res.data.actions[0].JournalUser ?? "",
                                        date:
                                          res.data.actions[0].JournalDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  if (
                                    res.data.trade.CancelledBy != null &&
                                    res.data.trade.CancelledBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Canceled",
                                      user: res.data.trade.CancelledBy ?? "",
                                      date:
                                        res.data.trade.CancelDate.split(
                                          " "
                                        )[0] +
                                        " " +
                                        res.data.trade.CancelTime.split(" ")[1],
                                      reason: res.data.trade.CancelReason,
                                    });
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].ReversalInitiatedBy !=
                                        null &&
                                      res.data.actions[0].ReversalInitiatedBy !=
                                        ""
                                    ) {
                                      actionsArray.push({
                                        type: "Reversal Initiated",
                                        user:
                                          res.data.actions[0]
                                            .ReversalInitiatedBy ?? "",
                                        date:
                                          res.data.actions[0]
                                            .ReversalInitiatedDateTime,
                                        reason:
                                          res.data.actions[0]
                                            .ReversalInitiatedReason,
                                      });
                                    }
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].ReversedBy != null &&
                                      res.data.actions[0].ReversedBy != ""
                                    ) {
                                      actionsArray.push({
                                        type: "Reversed",
                                        user:
                                          res.data.actions[0].ReversedBy ?? "",
                                        date:
                                          res.data.actions[0].ReversedDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  this.$refs.refReverseEntry.actionsData = actionsArray;
                                  this.$refs.refReverseEntry.reverseEntryTradeModal = true;
                   
                  } else {
                    let verifyList = [];
                    verifyList.push(item);
                    this.tradeMultipleChangeStatusOpr(
                      1,
                      verifyList,
                      "Reversed"
                    );
}


                                } else {
                                  this.notFoundMessage();
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          } else {
                            this.checkReversalInitiatedMessage(
                              res.data.message
                            );
                          }
                        }
                      );
                    } else {
                      this.checkEntryReversedMessage(res.data.message);
                    }
                  });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    undoInitiateReversal(item) {
      this.$checkAccessRight("CashTransactions", "UndoInitiateReversal").then(
        (res) => {
          if (res.data.valid) {
            if (item.Cancelled == 0) {
              if (item.ID != 0) {
                this.checkEntryReversed(
                  "UndoInitiateReversal",
                  item.TcUti
                ).then((res) => {
                  if (res.data.info == true) {
                    this.checkReversalInitiated(
                      "UndoInitiateReversal",
                      item.TcUti
                    ).then((res) => {
                      if (res.data.info == true) {
                        store
                          .dispatch(
                            "transfers/getTransferUndoInitiateReversal",
                            {
                              auth: this.user,
                              tradeId: item.ID,
                              tcUti: item.TcUti,
                            }
                          )
                          .then((res) => {
                            if (res.data.info == "Pass") {


                              if (this.getChoice()) {


                                
                              this.$refs.refUndoInitiateReversal.fetchedTradeData =
                                res.data.trade;

                              //set timeline datas
                              let actionsArray = [];
                              if (
                                res.data.trade.SavedBy != null &&
                                res.data.trade.SavedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Saved",
                                  user: res.data.trade.SavedBy ?? "",
                                  date:
                                    res.data.trade.SaveDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.SaveTime.split(" ")[1],
                                  reason: "-",
                                });
                              }

                              if (
                                res.data.trade.AmendedBy != null &&
                                res.data.trade.AmendedBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Amended",
                                  user: res.data.trade.AmendedBy ?? "",
                                  date:
                                    res.data.trade.AmendmentDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.AmendmentTime.split(" ")[1],
                                  reason: res.data.trade.AmendmentReason,
                                });
                              }
                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].VerificationUser !=
                                    null &&
                                  res.data.actions[0].VerificationUser != ""
                                ) {
                                  this.$refs.refUndoInitiateReversal.checkVerify = true;
                                  actionsArray.push({
                                    type: "Verified",
                                    user:
                                      res.data.actions[0].VerificationUser ??
                                      "",
                                    date:
                                      res.data.actions[0].VerificationDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].JournalUser != null &&
                                  res.data.actions[0].JournalUser != ""
                                ) {
                                  actionsArray.push({
                                    type: "Journal Created",
                                    user: res.data.actions[0].JournalUser ?? "",
                                    date: res.data.actions[0].JournalDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (
                                res.data.trade.CancelledBy != null &&
                                res.data.trade.CancelledBy != ""
                              ) {
                                actionsArray.push({
                                  type: "Canceled",
                                  user: res.data.trade.CancelledBy ?? "",
                                  date:
                                    res.data.trade.CancelDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.CancelTime.split(" ")[1],
                                  reason: res.data.trade.CancelReason,
                                });
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversalInitiatedBy !=
                                    null &&
                                  res.data.actions[0].ReversalInitiatedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversal Initiated",
                                    user:
                                      res.data.actions[0].ReversalInitiatedBy ??
                                      "",
                                    date:
                                      res.data.actions[0]
                                        .ReversalInitiatedDateTime,
                                    reason:
                                      res.data.actions[0]
                                        .ReversalInitiatedReason,
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversedBy != null &&
                                  res.data.actions[0].ReversedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Reversed",
                                    user: res.data.actions[0].ReversedBy ?? "",
                                    date: res.data.actions[0].ReversedDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              this.$refs.refUndoInitiateReversal.actionsData = actionsArray;
                              this.$refs.refUndoInitiateReversal.undoInitiateTradeModal = true;
                              



                   
                  } else {
                    let verifyList = [];
                    verifyList.push(item);
                    this.tradeMultipleChangeStatusOpr(
                      1,
                      verifyList,
                      "Undo Initiate Reversal"
                    );
}




                            } else {
                              this.notFoundMessage();
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.checkReversalInitiatedMessage(res.data.message);
                      }
                    });
                  } else {
                    this.checkEntryReversedMessage(res.data.message);
                  }
                });
              } else {
                this.tradeIDDoesntZeroMessage();
              }
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    cancelSelectedTrade(item) {
      this.$checkAccessRight("CashTransactions", "Cancel").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            this.checkJournalCreated("Cancel", item.TcUti).then((res) => {
              if (res.data.info == true) {
                if (item.ID != 0) {
                  this.tradeCancelReasonText().then((res) => {
                    if (res) {
                      store
                        .dispatch("transfers/getCanceledTransferById", {
                          auth: this.user,
                          tradeId: item.ID,
                          reason: this.canceledReasonText,
                          trade: item,
                        })
                        .then((res) => {
                          if (res.data.info == "Pass") {
                            this.tradeCancelledMessage();
                            this.runLastSearch();
                            this.canceledReasonText = null;
                          } else {
                            this.tradeCancelledErrorMessage(res.data.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      this.cancelOpearionNotDoneMessage();
                    }
                  });
                } else {
                  this.tradeIDDoesntZeroMessage();
                }
              } else {
                this.checkJournalCreatedMessage(res.data.message);
              }
            });
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    async tradeCancelReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("transfers.reason.cancel_reason"),
        text: self.$t("transfers.reason.please_enter_cancel_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "transfers.reason.please_enter_cancel_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("transfers.reason.submit"),
        cancelButtonText: self.$t("transfers.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.canceledReasonText = reason;
              resolve();
            } else {
              resolve(self.$t("transfers.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel"
            ? "textarea"
            : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          //if status journal set journal data
          if (status == "Create Journal") {
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setTradeData(trade));
              });
            }
            trades = setTradesData;
          }

          store
            .dispatch("transfers/tradeMultipleChangeTransactionStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
              reason: result.value ? result.value : null,
            })
            .then((res) => {
              self.loading = false;
              if(res.data.failedProcesses.length > 0){
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage()
                self.showMultiSelectProcessModal = true
                self.multiSelectProcessModalTableData = res.data.failedProcesses;
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();

                if(trades.length==1 && status == 'Revers Inititated'){
                  let val = {info: true, tradeId:trades[0].ID, tcUti:trades[0].tcUti, reason: result.value ? result.value : null}
                  this.reversaledTrade(val)
                }
                _.mapValues(this.allTransfersData, (v) => (v.selectedTrade = false));
                self.okMultiSelectProcessModal()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    tradeMultipleSelectCopyOpr(tradesCount, trades) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_copy_title"),
        text:
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_copy_message"),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // set trade data
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setTradeData(trade));
              });
            } 
            trades = setTradesData;
          store
            .dispatch("transfers/tradeMultipleSelectTransactionCopyOpr", {
              auth: self.user,
              trades: trades,
            })
            .then((res) => {
              self.loading = false;
              self.selectedMultipleTrades = [];

              if(res.data.failedCopyOpr.length > 0){
                self.multiSelectCopyWarningMessage()
                self.okMultiSelectProcessModal()
                self.showMultiSelectProcessModal = true
                self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
              } else if(res.data.info == 'not_found'){
                self.wrongToastMessage(res.data.message)
              } else {
                self.multiSelectAllTradesCopySuccessMessage();
                self.okMultiSelectProcessModal()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    okMultiSelectProcessModal(){
      this.showMultiSelectProcessModal = false
      this.multiSelectProcessModalTableData = []
      _.mapValues(this.allTransfersData, (v) => (v.selectedTrade = false));

      if (
        this.lastSearchData.find((data) => data.user == this.user.name)
      ) {
        this.runLastSearch();
      } else {
        this.getLast7DaysTrades();
      }
    },

    searchForTradesModal() {
      this.$checkAccessRight("CashTransactions", "Search").then((res) => {
        if (res.data.valid) {
          this.$refs.searchTrades.searchForTradesModal = true;
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createNewTrade() {
      this.$checkAccessRight("CashTransactions", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.refEnterNewTrade.enterNewTradeModal = true;
          this.$refs.refEnterNewTrade.getSavedDefaultEntries();
          this.$refs.refEnterNewTrade.getAllAccounts();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getSearchedData(data) {
      this.totalRows = data.length;
      if (data.length > 0) {
        this.visibleTransferInput = true;
      }
      this.allTransfersData = data;
      this.alltransfersNotFilterData = data;
      this.$refs.searchTrades.searchForTradesModal = false;
      this.cashRangeDate = null
      this.filterCashDateStart = null;
      this.filterCashDateEnd = null;
      this.filterCashDate = null;
      this.filterValueDateDateStart  = null
      this.filterValueDateDateEnd = null
      this.valueRangeDate = null
      this.filterValueDate = null;
    },
    getUpdatedAmendData(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdtransfers(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    copiedtransfers(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdJournalEntryArray(journalData) {
      if (journalData.success) {
        this.$refs.refViewJournalEntries.fetchedTradeData = journalData.trade;
        this.$refs.refViewJournalEntries.journalEntries = journalData.entries;
        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
        this.$refs.refViewJournalEntries.createJournalOperation = true;
      }
    },
    createdJournalEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    reversaledTrade(val) {
      const self = this;
      if (val.info) {
        this.runLastSearch();
        this.$swal({
          title: self.$t("transfers.mail.title"),
          text: self.$t("transfers.mail.message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("transfers.mail.send"),
          cancelButtonText: self.$t("transfers.mail.not_send"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("transfers/sendInitiateReversalMail", {
                auth: self.user,
                tcUti: val.tcUti,
                tradeId: val.tradeId,
                reason: val.reason,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  self.sendedMailSuccess();
                } else {
                  self.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    reversedEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    undoInitiate(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    verifiedTrade(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      // if (item.ExpiredOrder == 1) {
      //   return expiredOrderRowColor;
      // }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      // if (item.EntryType == "Execution") {
      //   return executedRowColor;
      // }
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    runLastSearch() {
      this.cashRangeDate = null
      this.filterCashDateStart = null;
      this.filterCashDateEnd = null;
      this.filterCashDate = null;
      this.filterValueDateDateStart  = null
      this.filterValueDateDateEnd = null
      this.valueRangeDate = null
      this.filterValueDate = null;


      if (
        typeof this.lastSearchData != "Object" &&
        this.lastSearchData.find((data) => data.user == this.user.name)
      ) {
        this.$checkAccessRight("CashTransactions", "Search").then((res) => {
          if (res.data.valid) {
            this.loading = true;
            if (this.getLastSearchTransfers) {
              store
                .dispatch("transfers/searchTransfersData", {
                  auth: this.user,
                  searchData: this.getLastSearchTransfers,
                })
                .then((res) => {
                  this.allTransfersData = [];
                  if (res.data.length > 0) {
                    this.visibleTransferInput = true;
                  } else {
                    this.visibleTransferInput = true;
                  }
                  this.totalRows = res.data.length;
                  this.allTransfersData = res.data;
                  this.alltransfersNotFilterData = res.data;
                  this.loading = false;
                })
                .catch((error) => {
                  this.loading = false;
                  console.log(error);
                });
            } else {
              this.notFoundSearchData();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
          this.getLast7DaysTrades();
        }
    },
    getfilterCashDate(e) {
      if (this.cashRangeDate != null) {
        this.$checkAccessRight("CashTransactions", "Search").then((res) => {
          if (res.data.valid) {

            let filterCashDateStart = null;
            let filterCashDateEnd = null;
            if (
              this.filterCashDateStart != null 
            ) {
              filterCashDateStart = this.YMDdateFormat(
                this.filterCashDateStart
              );
            }

            if (
              this.filterCashDateEnd != null
            ) {
            
              filterCashDateEnd = this.YMDdateFormat(this.filterCashDateEnd);
            }


            let searchData = this.setSearchData();




            searchData.entryDate = filterCashDateStart
            searchData.entryDateEnd = filterCashDateEnd

            this.loading = true;

            console.log(filterCashDateStart)

            if (filterCashDateStart != null) {
              store
                .dispatch("transfers/searchTransfersData", {
                  auth: this.user,
                  searchData: searchData,
                })
                .then((res) => {
                  this.allTransfersData = [];
                  if (res.data.length > 0) {
                    this.visibleTransferInput = true;
                    this.totalRows = res.data.length;
                    this.allTransfersData = res.data;
                  } else {
                    this.visibleTransferInput = false;
                  }

                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      }
    },
    getFilterValueDate() {
      if (this.valueRangeDate != null) {
        this.$checkAccessRight("CashTransactions", "Search").then((res) => {
          if (res.data.valid) {
            
            let filterValueDateDateStart = null;
            let filterValueDateDateEnd = null;

            if (
              this.filterValueDateDateStart != null 
            ) {
              filterValueDateDateStart = this.YMDdateFormat(
                this.filterValueDateDateStart
              );
          
            }

            if (
              
              this.filterValueDateDateEnd != null
            ) {
     
              filterValueDateDateEnd = this.YMDdateFormat(
                this.filterValueDateDateEnd
              );
            }


            let searchData = this.setSearchData();



            searchData.valueDate = filterValueDateDateStart
            searchData.valueDateEnd = filterValueDateDateEnd

            this.loading = true;
            if (filterValueDateDateStart != null) {
              store
                .dispatch("transfers/searchTransfersData", {
                  auth: this.user,
                  searchData: searchData,
                })
                .then((res) => {
                  this.allTransfersData = [];
                  if (res.data.length > 0) {
                    this.visibleTransferInput = true;
                    this.totalRows = res.data.length;
                    this.allTransfersData = res.data;
                  } else {
                    this.visibleTransferInput = false;
                  }
                  this.loading = false;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      }
    },

    setSearchData() {

let setSearch = {
  costCenter : this.tradeForm.costCenter,
  transactionType: this.tradeForm.transactionType != null ? this.tradeForm.transactionType : null,
  entryDate: this.tradeForm.entryDate != null
      ? this.DMYdateFormat2(this.tradeForm.entryDate)
      : null,
      entryDateEnd: null,
  entryTime: this.tradeForm.entryTime != null
      ? this.tradeForm.entryTime
      : null,
  valueDate: this.tradeForm.valueDate != null
      ? this.DMYdateFormat2(this.tradeForm.valueDate)
      : null,
      valueDateEnd: null,
  tcuser: this.tradeForm.tcuser != null ? this.tradeForm.tcuser : null,
  tcUti: this.tradeForm.tcUti != null ? this.tradeForm.tcUti : null,
  uniqueLinkID: this.tradeForm.uniqueLinkID != null ? this.tradeForm.uniqueLinkID : null,
  relatedTradeUti: this.tradeForm.relatedTradeUti != null ? this.tradeForm.relatedTradeUti : null,
  accountName1: this.tradeForm.accountName1 != null ? this.tradeForm.accountName1 : null,
  accountNumber1: this.tradeForm.accountNumber1 != null ? this.tradeForm.accountNumber1 : null,
  accountCode1: this.tradeForm.accountCode1 != null ? this.tradeForm.accountCode1 : null,
  accountType1: this.tradeForm.accountType1 != null ? this.tradeForm.accountType1 : null,
  ccy1: this.tradeForm.ccy1 != null ? this.tradeForm.ccy1 : null,
  amount1: this.tradeForm.amount1 != null
      ? parseFloat(this.tradeForm.amount1).toFixed(2)
      : null,
  cost1: this.tradeForm.cost1 != null
      ? parseFloat(this.tradeForm.cost1).toFixed(2)
      : null,
  description1: this.tradeForm.description1 != null ? this.tradeForm.description1 : null,
  note1: this.tradeForm.note1 != null ? this.tradeForm.note1 : null,
  accountName2: this.tradeForm.accountName2 != null ? this.tradeForm.accountName2 : null,
  accountNumber2: this.tradeForm.accountNumber2 != null ? this.tradeForm.accountNumber2 : null,
  accountCode2: this.tradeForm.accountCode2 != null ? this.tradeForm.accountCode2 : null,
  accountType2: this.tradeForm.accountType2 != null ? this.tradeForm.accountType2 : null,
  ccy2: this.tradeForm.ccy2 != null ? this.tradeForm.ccy2 : null,
  amount2: this.tradeForm.amount2 != null
      ? parseFloat(this.tradeForm.amount2).toFixed(2)
      : null,
  cost2: this.tradeForm.cost2 != null
      ? parseFloat(this.tradeForm.cost2).toFixed(2)
      : null,
  description2: this.tradeForm.description2 != null ? this.tradeForm.description2 : null,
  note2: this.tradeForm.note2 != null ? this.tradeForm.note2 : null,
  future3: null,
  future4: null,
  future5: null,
  future6: null,
  future7: null,
  future8: null,
  future9: null,
  future10: null,
  future11: null,
  future12: null,
  future13: null,
  future14: null,
  future15: null,
  entryDate: this.tradeForm.entryFrom != null
      ? this.YMDdateFormat(this.tradeForm.entryFrom)
      :null,
      entryDateEnd: this.tradeForm.entryFrom != null
      ? this.YMDdateFormat(this.tradeForm.entryTo)
      :null,
      valueDate: this.tradeForm.valueFrom != null
      ? this.YMDdateFormat(this.tradeForm.valueFrom)
      :null,
      valueDateEnd: this.tradeForm.valueTo != null
      ? this.YMDdateFormat(this.tradeForm.valueTo)
      :null,
  ID: this.tradeForm.tradeRef != null ? this.tradeForm.tradeRef : 0
};

return setSearch;
},

    verifySelectedTrade(item) {
      this.$checkAccessRight("CashTransactions", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkTradeOperations(item).then((res) => {
                if (res == true) {
                  store
                    .dispatch("transfers/getVerifySelectedTransferTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {


                        if (this.getChoice()) {


                          
                        this.$refs.refVerifyTrade.verifySelectedTradeModal = true;
                        this.$refs.refVerifyTrade.fetchedTradeData =
                          res.data.trade;

                        // set changes inputs
                        if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );

                          if (setChangedInputs.length > 0) {
                            this.$refs.refVerifyTrade.changedInputs = setChangedInputs;
                          }
                        }

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[1],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[1],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[1],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refVerifyTrade.actionsData = actionsArray;
                   
                  } else {
                    let verifyList = [];
                    verifyList.push(item);
                    this.tradeMultipleChangeStatusOpr(
                      1,
                      verifyList,
                      "Verify"
                    );
}





                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    amendUpdate(item) {
      this.$checkAccessRight("CashTransactions", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkJournalCreated("Amend", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  store
                    .dispatch("transfers/getAmendSelectedTransfer", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refAmendTrade.fetchedTradeData =
                          res.data.trade;
                        this.$refs.refAmendTrade.amendTradeModal = true;
                        this.$refs.refAmendTrade.getAllAccounts();

                        // set changes inputs
                        if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );

                          if (setChangedInputs.length > 0) {
                            this.$refs.refAmendTrade.changedInputs = setChangedInputs;
                          }
                        }

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[1],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[1],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refAmendTrade.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[1],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refAmendTrade.actionsData = actionsArray;
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createJournalEntries(item) {
      this.$checkAccessRight("CashTransactions", "CreateJournal").then(
        (res) => {
          if (res.data.valid) {
            store
              .dispatch("transfers/checkJournalTradeVerifyOperation", {
                auth: this.user,
                trade: item,
              })
              .then((res) => {
                if (res.data.info == "verified") {
                  this.checkJournalCreated("Journal", item.TcUti).then(
                    (res) => {
                      if (res.data.info == true) {
                        store
                          .dispatch("transfers/getCreateJournalTransferTrade", {
                            auth: this.user,
                            tradeId: item.ID,
                            tcUti: item.TcUti,
                          })
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              if (res.data.trade.Cancelled == "0") {


                                if (this.getChoice()) {


                                  
                                this.$refs.refCreateJournalTrade.fetchedTradeData =
                                  res.data.trade;

                                // set changes inputs
                                if (res.data.changedInputs) {
                                  let setChangedInputs = _.map(
                                    res.data.changedInputs,
                                    (value, key) => ({ key, value })
                                  );

                                  if (setChangedInputs.length > 0) {
                                    this.$refs.refCreateJournalTrade.changedInputs = setChangedInputs;
                                  }
                                }

                                //set timeline datas
                                let actionsArray = [];
                                if (
                                  res.data.trade.SavedBy != null &&
                                  res.data.trade.SavedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Saved",
                                    user: res.data.trade.SavedBy ?? "",
                                    date:
                                      res.data.trade.SaveDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.SaveTime.split(" ")[1],
                                    reason: "-",
                                  });
                                }

                                if (
                                  res.data.trade.AmendedBy != null &&
                                  res.data.trade.AmendedBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Amended",
                                    user: res.data.trade.AmendedBy ?? "",
                                    date:
                                      res.data.trade.AmendmentDate.split(
                                        " "
                                      )[0] +
                                      " " +
                                      res.data.trade.AmendmentTime.split(
                                        " "
                                      )[1],
                                    reason: res.data.trade.AmendmentReason,
                                  });
                                }
                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].VerificationUser !=
                                      null &&
                                    res.data.actions[0].VerificationUser != ""
                                  ) {
                                    this.$refs.refCreateJournalTrade.checkVerify = true;
                                    actionsArray.push({
                                      type: "Verified",
                                      user:
                                        res.data.actions[0].VerificationUser ??
                                        "",
                                      date:
                                        res.data.actions[0]
                                          .VerificationDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].JournalUser != null &&
                                    res.data.actions[0].JournalUser != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Journal Created",
                                      user:
                                        res.data.actions[0].JournalUser ?? "",
                                      date: res.data.actions[0].JournalDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                if (
                                  res.data.trade.CancelledBy != null &&
                                  res.data.trade.CancelledBy != ""
                                ) {
                                  actionsArray.push({
                                    type: "Canceled",
                                    user: res.data.trade.CancelledBy ?? "",
                                    date:
                                      res.data.trade.CancelDate.split(" ")[0] +
                                      " " +
                                      res.data.trade.CancelTime.split(" ")[1],
                                    reason: res.data.trade.CancelReason,
                                  });
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      null &&
                                    res.data.actions[0].ReversalInitiatedBy !=
                                      ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversal Initiated",
                                      user:
                                        res.data.actions[0]
                                          .ReversalInitiatedBy ?? "",
                                      date:
                                        res.data.actions[0]
                                          .ReversalInitiatedDateTime,
                                      reason:
                                        res.data.actions[0]
                                          .ReversalInitiatedReason,
                                    });
                                  }
                                }

                                if (res.data.actions.length > 0) {
                                  if (
                                    res.data.actions[0].ReversedBy != null &&
                                    res.data.actions[0].ReversedBy != ""
                                  ) {
                                    actionsArray.push({
                                      type: "Reversed",
                                      user:
                                        res.data.actions[0].ReversedBy ?? "",
                                      date:
                                        res.data.actions[0].ReversedDateTime,
                                      reason: "-",
                                    });
                                  }
                                }

                                this.$refs.refCreateJournalTrade.actionsData = actionsArray;
                                this.$refs.refCreateJournalTrade.createJournalTradeModal = true;

                   
                              } else {
                                let verifyList = [];
                                verifyList.push(item);
                                this.tradeMultipleChangeStatusOpr(
                                  1,
                                  verifyList,
                                  "Create Journal"
                                );
}






                              } else {
                                this.canceledToastMessage();
                              }
                            } else {
                              this.notFoundMessage();
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.checkJournalCreatedMessage(res.data.message);
                      }
                    }
                  );
                } else {
                  this.checkTradeVerifyOperationMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("CashTransactions", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            store
              .dispatch("transfers/getViewSelectedTransferTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                  this.$refs.ViewSelectedTrade.fetchedTradeData =
                    res.data.trade;



                    // set changes inputs
                    if (res.data.changedInputs) {
                        let setChangedInputs = _.map(
                          res.data.changedInputs,
                          (value, key) => ({ key, value })
                        );

                        if (setChangedInputs.length > 0) {
                          this.$refs.ViewSelectedTrade.changedInputs = setChangedInputs;
                        }
                      }

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? "",
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime.split(" ")[1],
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? "",
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime.split(" ")[1],
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != ""
                    ) {
                      this.$refs.ViewSelectedTrade.checkVerify = true;
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? "",
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != ""
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? "",
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != ""
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? "",
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime.split(" ")[1],
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? "",
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? "",
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    copySelectedTrade(item) {
      this.$checkAccessRight("CashTransactions", "Save").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("transfers/getCopySelectedTransferTrade", {
              auth: this.user,
              tradeId: item.ID,
              tcUti: item.TcUti,
            })
            .then((res) => {
              if (res.data.info == "Pass") {



                if (this.getChoice()) {


                  this.$refs.refCopyTrade.fetchedTradeData = res.data.trade;
                this.$refs.refCopyTrade.copyTradeModal = true;
                this.$refs.refCopyTrade.getAllAccounts();

                //set timeline datas
                let actionsArray = [];
                if (
                  res.data.trade.SavedBy != null &&
                  res.data.trade.SavedBy != ""
                ) {
                  actionsArray.push({
                    type: "Saved",
                    user: res.data.trade.SavedBy ?? "",
                    date:
                      res.data.trade.SaveDate.split(" ")[0] +
                      " " +
                      res.data.trade.SaveTime.split(" ")[1],
                    reason: "-",
                  });
                }

                if (
                  res.data.trade.AmendedBy != null &&
                  res.data.trade.AmendedBy != ""
                ) {
                  actionsArray.push({
                    type: "Amended",
                    user: res.data.trade.AmendedBy ?? "",
                    date:
                      res.data.trade.AmendmentDate.split(" ")[0] +
                      " " +
                      res.data.trade.AmendmentTime.split(" ")[1],
                    reason: res.data.trade.AmendmentReason,
                  });
                }
                if (res.data.actions.length > 0) {
                  if (
                    res.data.actions[0].VerificationUser != null &&
                    res.data.actions[0].VerificationUser != ""
                  ) {
                    actionsArray.push({
                      type: "Verified",
                      user: res.data.actions[0].VerificationUser ?? "",
                      date: res.data.actions[0].VerificationDateTime,
                      reason: "-",
                    });
                  }
                }

                if (res.data.actions.length > 0) {
                  if (
                    res.data.actions[0].JournalUser != null &&
                    res.data.actions[0].JournalUser != ""
                  ) {
                    actionsArray.push({
                      type: "Journal Created",
                      user: res.data.actions[0].JournalUser ?? "",
                      date: res.data.actions[0].JournalDateTime,
                      reason: "-",
                    });
                  }
                }

                if (
                  res.data.trade.CancelledBy != null &&
                  res.data.trade.CancelledBy != ""
                ) {
                  actionsArray.push({
                    type: "Canceled",
                    user: res.data.trade.CancelledBy ?? "",
                    date:
                      res.data.trade.CancelDate.split(" ")[0] +
                      " " +
                      res.data.trade.CancelTime.split(" ")[1],
                    reason: res.data.trade.CancelReason,
                  });
                }

                if (res.data.actions.length > 0) {
                  if (
                    res.data.actions[0].ReversalInitiatedBy != null &&
                    res.data.actions[0].ReversalInitiatedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Reversal Initiated",
                      user: res.data.actions[0].ReversalInitiatedBy ?? "",
                      date: res.data.actions[0].ReversalInitiatedDateTime,
                      reason: res.data.actions[0].ReversalInitiatedReason,
                    });
                  }
                }

                if (res.data.actions.length > 0) {
                  if (
                    res.data.actions[0].ReversedBy != null &&
                    res.data.actions[0].ReversedBy != ""
                  ) {
                    actionsArray.push({
                      type: "Reversed",
                      user: res.data.actions[0].ReversedBy ?? "",
                      date: res.data.actions[0].ReversedDateTime,
                      reason: "-",
                    });
                  }
                }

                this.$refs.refCopyTrade.actionsData = actionsArray;
                   
                  } else {
                    let verifyList = [];
                    verifyList.push(item);
                    this.tradeMultipleSelectCopyOpr(
                      1,
                      verifyList,
                    );
}








              
              } else {
                this.notFoundMessage();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getAmendDetails(item) {
      this.$checkAccessRight("CashTransactions", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            if (item.ID != 0) {
              this.$router.push({
                path: `/cashTransactions/amend-details/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    viewJournalEntries(item) {
      this.$checkAccessRight("Journal", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == "0") {
            this.checkJournalCreated("JournalDisplay", item.TcUti).then(
              (res) => {
                if (res.data.info == true) {
                  //set and check trade data
                  let tradeData = this.setTradeData(item);
                  /////

                  store
                    .dispatch("transfers/getViewTransferJournalTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                      trade: tradeData,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refViewJournalEntries.journalEntries =
                          res.data.entries;

                        this.$refs.refViewJournalEntries.createJournalOperation = false;

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? "",
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime.split(" ")[1],
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != ""
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? "",
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime.split(" ")[1],
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != ""
                          ) {
                            this.$refs.refViewJournalEntries.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user: res.data.actions[0].VerificationUser ?? "",
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != ""
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? "",
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != ""
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? "",
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime.split(" ")[1],
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? "",
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != ""
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? "",
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refViewJournalEntries.actionsData = actionsArray;
                        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;

                        if (res.data.amount_info == "not_zero") {
                          this.checkJournalOperationsMessage(
                            res.data.message,
                            10000
                          );
                        }
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              }
            );
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    checkTradeOperations(trade) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("transfers/checkTransferTradeVerifyOperation", {
            auth: this.user,
            trade: trade,
          })
          .then((res) => {
            if (res.data.info == "not_verified") {
              store
                .dispatch("transfers/checkTransferTradeUserOperation", {
                  auth: this.user,
                  trade: trade,
                })
                .then((res) => {
                  if (res.data.info == "user_doesnt_same") {
                    if (trade.EntryType == "Order") {
                      this.$swal({
                        title: this.$t("transfers.swal.order_trade"),
                        text: this.$t(
                          "transfers.swal.entry_saved_order_want_amend_trade"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: this.$t(
                          "transfers.swal.confirm_button"
                        ),
                        cancelButtonText: this.$t(
                          "transfers.swal.cancel_button"
                        ),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.amendUpdate(trade);
                        }
                      });
                    } else {
                      resolve(true);
                    }
                  } else if (
                    res.data.info == "same_user_amend" ||
                    res.data.info == "same_user_save"
                  ) {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  } else {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.checkTradeVerifyOperationMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUserOperationMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("transfers/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("transfers.messages.tc_uti_not_found"));
      }
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("transfers/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("transfers.messages.tc_uti_not_found"));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("transfers/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("transfers.messages.tc_uti_not_found"));
      }
    },

    setTradeData(val) {
      let ccyDescription = null
      if(val.AccountCode1 != null) {
        axiosIns
          .post(`selectAsAccountCode`, { auth: this.user, code: val.AccountCode1 })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.code) {
                if (res.data.code.AccountCcy == "HOL") {
                  ccyDescription = "Security Description*";
                } else {
                  ccyDescription = "Currency*";
                }
              }
            }
          })
          .catch((error) => reject(error));
      }

      if (val.TransactionType != "Internal Transfer") {
        val.AccountName2 = null;
        val.AccountNumber2 = null;
        val.AccountCode2 = null;
        val.AccountType2 = null;
        val.Ccy2 = null;
        val.Amount2 = null;
        val.Cost2 = null;
        val.Description2 = null;
        val.Note2 = null;
      }
      

      let setTrade = {
        transactionType: val.TransactionType != null ? val.TransactionType : null,
        costCenter: val.CostCenter != null ? val.CostCenter : null,
        entryDate: val.EntryDate != null ? this.dateFormat(val.EntryDate) : null,
        entryTime:
          val.EntryTime != null ? val.EntryTime : null,
        valueDate: val.ValueDate != null ? this.dateFormat(val.ValueDate) : null,
        tcuser: val.TcUser != null ? val.TcUser : null,
        tcUti: val.TcUti != null ? val.TcUti : null,
        uniqueLinkID: val.UniqueLinkId != null ? val.UniqueLinkId : null,
        relatedTradeUti: val.RelatedTradeUti != null ? val.RelatedTradeUti : null,
        accountName1: val.AccountName1 != null ? val.AccountName1 : null,
        accountNumber1: val.AccountNumber1 != null ? val.AccountNumber1 : null,
        accountCode1: val.AccountCode1 != null ? val.AccountCode1 : null,
        accountType1: val.AccountType1 != null ? val.AccountType1 : null,
        ccy1: val.Ccy1 != null ? val.Ccy1 : null,
        amount1: val.Amount1 != null ? parseFloat(val.Amount1).toFixed(2) : null,
        cost1: val.Cost1 != null ? parseFloat(val.Cost1).toFixed(2) : null,
        description1: val.Description1 != null ? val.Description1 : null,
        note1: val.Note1 != null ? val.Note1 : null,
        accountName2: val.AccountName2 != null ? val.AccountName2 : null,
        accountNumber2: val.AccountNumber2 != null ? val.AccountNumber2 : null,
        accountCode2: val.AccountCode2 != null ? val.AccountCode2 : null,
        accountType2: val.AccountType2 != null ? val.AccountType2 : null,
        ccy2: val.Ccy2 != null ? val.Ccy2 : null,
        amount2: val.Amount2 != null ? parseFloat(val.Amount2).toFixed(2) : null,
        cost2: val.Cost2 != null ? parseFloat(val.Cost2).toFixed(2) : null,
        description2: val.Description2 != null ? val.Description2 : null,
        note2: val.Note2 != null ? val.Note2 : null,
        tradeRef: val.ID != 0 ? val.ID : 0,
        savedBy: val.SavedBy != null ? val.SavedBy : null,
        saveDate: val.SaveDate != null ? val.SaveDate : null,
        saveTime: val.SaveTime != null ? val.SaveTime : null,
        amendedBy: val.AmendedBy != null ? val.AmendedBy : null,
        amendmentDate: val.AmendmentDate != null ? val.AmendmentDate : null,
        amentmentTime: val.AmendmentTime != null ? val.AmendmentTime : null,
        amendmentReason: val.AmendmentReason != null ? val.AmendmentReason : null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        ccyDescription: ccyDescription != null ? ccyDescription : 'Currency*',
        ID: val.ID != 0 ? val.ID : 0,
      };

      return setTrade;
    },

    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("transfers.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("transfers.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    canceledToastMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("transfers.messages.trade_cancelled_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cancelOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("transfers.messages.cancelled_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_processes_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_warning"
          ),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t(
            "multiCheckBoxTitles.multiselect_copy_success"
          ),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    sendedMailSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.mail.mail_send_success"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    tradeCancelledErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("transfers.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("transfers.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    tradeCancelledMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.toast_messages.trade_cancelled_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successOrderColumnsMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.toast_messages.success_order_columns"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("transfers.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("transfers.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    YMDdateFormat2(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss")._i;
      }
    },
    clearCashDate() {
      this.cashRangeDate = null
      this.filterCashDateStart = null;
      this.filterCashDateEnd = null;
      this.filterCashDate = null;
      this.runLastSearch();
    },
    clearValueDate() {
      this.filterValueDateDateStart  = null
      this.filterValueDateDateEnd = null
      this.valueRangeDate = null
      this.filterValueDate = null;
      this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // if(this.windowTop > 300) {
      //   console.log(this.$refs.reftransfersTable.$el)
      // }
    },
    getChoice() {
      return this.userData.personal_settings.find((e) => e.key == "transfer_tickets").choice;
    },

    
    exportXlsx() {

      let columns = []
      this.tableColumns.forEach(element => {
        columns.push(element.key)
        
      });

      let datas = this.filteredTrades.map(item => {
        const orderedItem = {};
        columns.forEach(column => {

          if(column == 'EntryType2'){
              orderedItem[column] = 'Main Entry';
            }


      
          if (column in item) {
          
            if(column == 'Cancelled'){
              if(item[column] == 1){
                orderedItem[column] = 'Cancelled';
              }
              else{
                orderedItem[column] = 'Not Cancelled';

              }
            }
            else{
              orderedItem[column] = item[column];

            }

          }
        });
        return orderedItem;
      });

      let columnNames = []

      this.tableColumns.forEach(element => {
        columnNames.push(element.label)
        
      });

      store
        .dispatch("transfers/exportXlsx",{data:datas,headings:columnNames})
        .then((res) => {

          let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });

   
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download","transfer.xlsx");
          document.body.appendChild(link);
          link.click();

         
        })
        .catch((error) => {
          console.log(error);
        });
    },



    exportCsv() {


      let columns = []
      this.tableColumns.forEach(element => {
        columns.push(element.key)
        
      });

      let datas = this.filteredTrades.map(item => {
        const orderedItem = {};
        columns.forEach(column => {


          if(column == 'EntryType2'){
              orderedItem[column] = 'Main Entry';
            }

      
          if (column in item) {
          
            if(column == 'Cancelled'){
              if(item[column] == 1){
                orderedItem[column] = 'Cancelled';
              }
              else{
                orderedItem[column] = 'Not Cancelled';

              }
            }
            else{
              orderedItem[column] = item[column];

            }

          }
        });
        return orderedItem;
      });

      let columnNames = []

      this.tableColumns.forEach(element => {
        columnNames.push(element.label)
        
      });

      store
        .dispatch("transfers/exportCsv",{data:datas,headings:columnNames})
        .then((res) => {

          let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "transfer.csv");
          document.body.appendChild(link);
          link.click();

         
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("transfersShow", val);
      },
    },
    valueRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterValueDateDateStart = this.valueRangeDate.split(" to ")[0];
            this.filterValueDateDateEnd = this.valueRangeDate.split(" to ")[1];

            // this.getFilterTradeDate();
            if (
              this.filterValueDateDateStart != "null" &&
              this.filterValueDateDateEnd != "null"
            ) {
              //this.getFilterTradeDate();
            } else {
              this.filterValueDateDateStart = null;
              this.filterValueDateDateEnd = null;
            }
          }
          else{
            this.filterValueDateDateStart = this.valueRangeDate;
          }
        }
      },
    },
    cashRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterCashDateStart = this.cashRangeDate.split(" to ")[0];
            this.filterCashDateEnd = this.cashRangeDate.split(" to ")[1];

            // this.listRefresh();
            if (
              this.filterCashDateStart != "null" &&
              this.filterCashDateEnd != "null"
            ) {
              //this.listRefresh();
            } else {
              this.filterCashDateStart = null;
              this.filterCashDateEnd = null;
            }
          }
          else{
            this.filterCashDateStart = this.cashRangeDate;
          }
        }
      },
    },
    // treeTablecolumns: {
    //   handler: function(val, before) {
    //     this.loading = true;
    //     if (val.length > 0) {
    //       let filteredColumns = [];
    //       filteredColumns.push({
    //         key: "ID",
    //         label: "ID",
    //         // thClass: "text-left",
    //         tdClass: "text-center ID_background",
    //         // thStyle: 'width: 30%',
    //         // sortable: true,
    //         class: "text-center sticky-column",
    //         variant: "primary",
    //       });
    //       this.tableOrjinalColumns.forEach((o) => {
    //         if (val.find((v) => v.value == o.key)) {
    //           filteredColumns.push(o);
    //         }
    //       });
    //       this.tableColumns = filteredColumns;
    //     } else {
    //       this.tableColumns = this.tableOrjinalColumns;
    //     }
    //     this.loading = false;
    //   },
    // },
    treeStatus: {
      handler: function(val, before) {
        let allFilteredData = [];

        if (val.length > 0) {
          val.forEach((s) => {
            if (s.value == "Verified") {
              let allRawData = JSON.parse(
                JSON.stringify(this.alltransfersNotFilterData)
              );


              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Journal Created") {
              let allRawData = JSON.parse(
                JSON.stringify(this.alltransfersNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              console.log(filteredData)

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Revers Inititated") {
              let allRawData = JSON.parse(
                JSON.stringify(this.alltransfersNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reversed") {
              let allRawData = JSON.parse(
                JSON.stringify(this.alltransfersNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 1,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Cancelled") {
              let allRawData = JSON.parse(
                JSON.stringify(this.alltransfersNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Cancelled: 1,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            }
          });

          let uniqueData = _.uniqBy(allFilteredData, function(e) {
            return e.ID;
          });

          this.allTransfersData = uniqueData;
          this.totalRows = uniqueData.length;
        } else {
          this.allTransfersData = this.alltransfersNotFilterData;
          this.totalRows = this.alltransfersNotFilterData.length;
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              Verified: 0,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Create Journal",
              title: this.$t("multiCheckBoxTitles.journal"),
              icon: "ClipboardIcon",
              color: "#0b4666",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Revers Inititated",
              title: this.$t("multiCheckBoxTitles.revers_initiated"),
              icon: "PlayIcon",
              color: "rgb(255, 255, 153)",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Reversed",
              title: this.$t("multiCheckBoxTitles.reversed"),
              icon: "CornerUpLeftIcon",
              color: "coral",
            });
          }

          if (
            _.filter(val, {
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Undo Initiate Reversal",
              title: this.$t("multiCheckBoxTitles.undo_initiate_reversal"),
              icon: "ChevronsLeftIcon",
              color: "black",
            });
          }

          if (
            _.filter(val, {
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Cancel",
              title: this.$t("multiCheckBoxTitles.cancel"),
              icon: "XSquareIcon",
              color: "#ea5455",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },

  },
};
</script>

<style>
.treeSelect .vue-treeselect__control {
  height: 38px !important;
  max-height: 38px !important;
}

.table-executed {

background-color: #e9fafa !important;

}

.table-verified {

  background-color: #00ff7f94 !important;

}

.table-journalCreate {

background-color: #e1d7f5 !important;

}

.table-expiredOrder {

background-color: silver !important;

}

.table-reverseInitiated {

background-color: #ffff99 !important;

}




.table-reversed {

background-color: #f8ac91!important;

}


.table-canceled {

  background-color: #ffb8b8 !important;

}


.TcUti {
  width: 100%;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
  min-height: 38.59px !important;
}


.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-height: 38.59px !important;
}


.sticky-column { position: sticky; background-color: #fff; z-index: 1; }
.sticky-column:nth-child(1) { left: 0; }
.sticky-column:nth-child(2) { left: 50px; }
.other-col {  z-index: 0; }

.b-table-sticky-column:last-child {
  right: 0;
}




.ID_background {
  background-color: gainsboro !important;
}


.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}


.b-table-sticky-header{
  max-height : unset !important;
  height: inherit !important;

}

.hide-rows thead tr[role="row"] { display: none; }

.table-padding {padding: 1rem !important; white-space:nowrap}

</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>
